define('../bower_components/dwx-widget-my-documents/src/controllers/myDocumentsCtrl',["require", "exports", '../domain/serviceData', '../domain/kiid', '../domain/process-type-rto', '../domain/process-type-simula', '../domain/process-type-max', '../domain/process-type-advisor-documents', '../domain/rto-second-step-checkbox-conf', '../domain/report-file-name-map', '../../../../scripts/owui-widget.constant'], function (require, exports, serviceData, Kiid, ProcessTypeRTO, ProcessTypeSimula, ProcessTypeMax, ProcessTypeAdvisorDocuments, RTOSecondStepConfig, ReportFileNameMap, OwuiWidgetConstant) {
    exports.showDetails = function (document, documentCategory) {
    };
    exports.changeOrderByFilter = function (indexFilter, isGridView, categoryName, fieldName, excludeReverse) {
    };
    var MyDocumentsController = (function () {
        function MyDocumentsController($scope, $filter, myDocumentsService, EventService, paginationService, initConfigService, $window, $translatePartialLoaderProvider, $translate, $timeout, remotePaginationService, myDocumentsConfigService, groupedTableService, $q, $rootScope, $location, $interval, configurationService) {
            var _this = this;
            this.$scope = $scope;
            this.$filter = $filter;
            this.myDocumentsService = myDocumentsService;
            this.EventService = EventService;
            this.paginationService = paginationService;
            this.$window = $window;
            this.$translatePartialLoaderProvider = $translatePartialLoaderProvider;
            this.$translate = $translate;
            this.$timeout = $timeout;
            this.remotePaginationService = remotePaginationService;
            this.myDocumentsConfigService = myDocumentsConfigService;
            this.groupedTableService = groupedTableService;
            this.$q = $q;
            this.$rootScope = $rootScope;
            this.$location = $location;
            this.$interval = $interval;
            this.configurationService = configurationService;
            this.owuiWidgetCommunicationName = 0 /* WIDGET_COMMUNICATION */;
            this.groupByDocumentLabel = 'document';
            this.documentList = [];
            this.filteredDocumentsForTableView = {};
            this.modalCategory = '';
            this.fieldForGroupedTable = ["category", "documents_length"];
            this.rowOpened = [];
            this.indexOrderByFilter = 0;
            this.indexGroupByFilter = 0;
            this.reverseOrder = {};
            this.reverseOrderForGroupedTable = {};
            this.reverseOrderForBESorting = {};
            this.paginationForTabView = {};
            this.paginationForGridView = {};
            this.partialPageForTabView = 0;
            this.totalForTabView = 0;
            this.innerPagination = [];
            this.isGroupedBy = false;
            this.originalLastGroupedItems = [];
            this.expanded = [];
            this.acknowledgmentMap = {};
            this.kiidAcknowledgmentMap = {};
            this.showProposal = {};
            this.rtoAcknowledgmentMap = {};
            this.manlevaCheckMap = {};
            this.enableDownloadAll = false;
            this.appropriateCheckMap = [];
            this.badges = [];
            this.selectedDocumentProductsMap = {};
            this.selectedDocumentESGProductsMap = {};
            this.selectedDocumentProductsKidMap = {};
            this.documentSelectedGroup = [];
            this.counterPdf = 0;
            this.downloadPdfLimit = 50;
            this.isPdobDocumentsSelected = false;
            this.isAdvisorSearch = false;
            this.advisorCommunicationUnreadCounter = 0;
            this.advisorReportingUnreadCounter = 0;
            this.initWidget = function (configuration) {
                var parsedConfiguration = JSON.parse(configuration);
                if (!!parsedConfiguration.documentCategories.summaryPdf) {
                    parsedConfiguration.documentCategories.summaryPdf.listViewConf.listViewData[0].hasTagNew = true;
                    parsedConfiguration.documentCategories.summaryPdf.showUnreadBadge = true;
                }
                _this.myDocumentsConfigService.initConfiguration(parsedConfiguration);
                if (_this.myDocumentsConfigService.myDocConfig.enablePolling) {
                    _this.EventService.publish('disable-header', [false]);
                    _this.onboardingContractPolling = _this.$interval(function () {
                        if (_this.filteredDocuments.length === 0) {
                            _this.myDocumentsService.getDocumentSummary(_this.myDocumentsConfigService.myDocConfig.categories, _this.docTagsForSummary).then(function (documentSummary) {
                                _this.badges = documentSummary;
                                _this.setLandingPage();
                                _this.getPaginatedDocuments(_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex, 1);
                            }, function (error) {
                                _this.handlerWidgetError(error);
                                _this.$interval.cancel(_this.onboardingContractPolling);
                            });
                        }
                    }, 10000);
                }
                if (_this.widgetConfig.widgetInfos != null && _this.widgetConfig.widgetInfos.IS_DYNAMIC != null && _this.widgetConfig.widgetInfos.IS_DYNAMIC.value == "true") {
                    _this.myDocumentsService.getDocumentCategories(_this.widgetId).then(function (documentCategoriesToShow) {
                        _this.cleanDynamicWidgetConfiguration(documentCategoriesToShow);
                        _this.initWidgetAfterConfiguration();
                    }, function (error) {
                        _this.handlerWidgetError(error);
                    });
                }
                else {
                    if (_this.isAdvisorDocWidget()) {
                        _this.myDocumentsService.getAdCommunicationDocumentUnread.get({}, function (communicationSummary) {
                            console.log("asdasd", communicationSummary);
                            _this.advisorCommunicationUnreadCounter = communicationSummary.result;
                        });
                        _this.myDocumentsService.getAdReportingDocumentUnread.get({}, function (reportingSummary) {
                            console.log("asdasd", reportingSummary);
                            _this.advisorReportingUnreadCounter = reportingSummary.result;
                        });
                        _this.myDocumentsService.getDocumentType('disposition').then(function (response) {
                            _this.docTypeList = response;
                            _this.initWidgetAfterConfiguration();
                        }, function (error) {
                            _this.initWidgetAfterConfiguration();
                        });
                    }
                    else {
                        _this.initWidgetAfterConfiguration();
                    }
                }
            };
            this.setWidgetReady = function () {
                _this.$scope.$evalAsync(function () {
                    _this.$timeout(function () {
                        _this.widgetConfig.status.mode = 'on';
                        _this.$scope.$apply();
                        eqjs.refreshNodes();
                        eqjs.query(undefined, true);
                        console.log("calling eqjs");
                    }, 1500);
                    _this.$translate.refresh();
                });
            };
            this.handlerWidgetError = function (error) {
                if (typeof (error.type) !== 'undefined' && error.type == 'function') {
                    _this.widgetConfig.status.message = "dashboard.error.connection_error";
                    _this.EventService.publish('http-response-error', [error.error]);
                    _this.widgetConfig.status.mode = 'error';
                }
                else {
                    _this.widgetConfig.status.message = error['message'];
                    _this.EventService.publish('http-response-error', [error['messageKey']]);
                    _this.widgetConfig.status.mode = 'error';
                }
            };
            this.initWidgetSettings = function (landingCategory, view) {
                console.log(_this.myDocumentsConfigService.myDocConfig);
                if (_this.$translatePartialLoaderProvider != undefined) {
                    _this.$translatePartialLoaderProvider.addPart('bower_components/dwx-widget-my-documents');
                }
                _this.filteredDocuments = [];
            };
            this.initParameterForFrontPagination = function () {
                _this.paginationForGridView[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex] = new _this.paginationService([], _this.setNumberItemsInPage(), 1);
                _this.documentDetails = {};
                _this.$scope['detailsTabIndex'] = 0;
            };
            this.initDefaultGrouping = function () {
                var category = _this.myDocumentsConfigService.myDocConfig.documentCategories[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex];
                var defaultGrouping = (category && category.listViewConf.defaultGrouping) ? category.listViewConf.defaultGrouping : undefined;
                _this.groupByDocumentLabel = (defaultGrouping != undefined && defaultGrouping) ? defaultGrouping : "document";
            };
            this.setLandingPage = function () {
                var y = 0;
                var landingCategory;
                angular.forEach(_this.badges, function (category) {
                    var documentCategory = _this.myDocumentsConfigService.myDocConfig.documentCategories[category.documentCategory];
                    var categoryPriority = documentCategory ? documentCategory.categoryPriority : 0;
                    var x = categoryPriority + ((category.documentTypes.unread > 0) ? 100 : 0);
                    if (x > y) {
                        y = x;
                        landingCategory = category.documentCategory;
                    }
                });
                if (_this.badges[0].documentTypes && typeof (_this.badges[0].documentTypes.toSign) != 'undefined' && _this.badges[0].documentTypes.toSign > 0) {
                    _this.documentFilterIndex = 'toSign';
                }
                else {
                    _this.documentFilterIndex = '';
                }
                console.log("landingCategory", landingCategory);
                _this.myDocumentsConfigService.myDocConfig.setDocumentCategoryIndex(landingCategory);
            };
            this.setSelectedOrdering = function () {
                _this.selectedOrdering = undefined;
                var docCategory = _this.myDocumentsConfigService.myDocConfig.documentCategories[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex];
                if (!!docCategory) {
                    angular.forEach(docCategory.gridViewConf.orderByList, function (orderItem, key) {
                        if (orderItem.title == docCategory.defaultOrdering.title) {
                            _this.selectedOrdering = docCategory.defaultOrdering;
                        }
                    });
                }
            };
            this.getGenericDocumentsCallback = function (genericDocuments, controllerInstance) {
                controllerInstance.documentList = genericDocuments;
                if (controllerInstance.myDocumentsConfigService.myDocConfig.documentCategoryIndex != 'unread') {
                    controllerInstance.filteredDocuments = controllerInstance.$filter('filter')(controllerInstance.documentList, { topic: controllerInstance.myDocumentsConfigService.myDocConfig.documentCategoryIndex });
                }
                else {
                    controllerInstance.filteredDocuments = controllerInstance.documentList;
                }
                controllerInstance.changeOrderByFilter(controllerInstance.indexOrderByFilter, true, controllerInstance.myDocumentsConfigService.myDocConfig.documentCategoryIndex, controllerInstance.myDocumentsConfigService.myDocConfig.orderByListIndex[controllerInstance.indexOrderByFilter]);
                for (var x in controllerInstance.documentList) {
                    if (controllerInstance.documentList[x].attributes.totalAmount == 'null' || controllerInstance.documentList[x].attributes.totalAmount == 'NULL') {
                        controllerInstance.documentList[x].attributes.totalAmount = '0';
                    }
                }
                controllerInstance.initPaginations();
                controllerInstance.setWidgetReady();
            };
            this.getPaginatedDocuments = function (documentCategory, index) {
                var myDocConfig = _this.myDocumentsConfigService.myDocConfig;
                _this.setWaitingState();
                var tagList = myDocConfig.documentCategories[myDocConfig.documentCategoryIndex].tagsList || [];
                var docTags = (_this.myDocumentsConfigService.myDocConfig.showFilters && _this.documentFilterIndex !== '') ? tagList.concat(_this.documentFilterIndex) : tagList;
                _this.genericDocumentsPaginatedServiceParams.setDocCategory(documentCategory).setPage(index).setSize(_this.setNumberItemsInPage()).setTagList(docTags).setAdvisorSearchUserCode(_this.advisorSearchCustomerCode).setAdvisorSearchNominative(_this.advisorSearchNominative).setAdvisorSearchStartDate(_this.$filter('date')(_this.advisorSearchStartDate, 'dd/MM/yyyy')).setAdvisorSearchEndDate(_this.$filter('date')(_this.advisorSearchEndDate, 'dd/MM/yyyy')).setAdvisorSearchDocType(_this.advisorSearchDocType);
                _this.getDocuments(_this.genericDocumentsPaginatedServiceParams, _this.getPaginatedDocumentsCallback);
            };
            this.getPaginatedDocumentsCallback = function (genericDocuments, controllerInstance, reportingIcaroDocuments) {
                var adaptedReportingIcaroDocuments = [];
                if (!!reportingIcaroDocuments) {
                    adaptedReportingIcaroDocuments = reportingIcaroDocuments.map(function (report) {
                        var date = new Date(report.referenceDate);
                        var day = date.getDate().toString().length > 1 ? date.getDate() : "0" + date.getDate();
                        var month = date.getMonth() >= 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
                        return {
                            attributes: { TYPE: "RENDICONTAZIONE", COMPANY: "AZIMUT" },
                            tags: report.read ? ["read"] : ["unread"],
                            title: ReportFileNameMap.map[report.flowCode].replace('@@', "" + day + "/" + month + "/" + new Date(report.referenceDate).getFullYear()).replace('**', report.extraInfo.documentNumber),
                            topic: "summaryPdf",
                            isIcaroDocument: true,
                            id: report.id
                        };
                    });
                }
                genericDocuments = adaptedReportingIcaroDocuments.concat(genericDocuments);
                controllerInstance.filteredDocuments = genericDocuments;
                if (controllerInstance.myDocumentsConfigService.myDocConfig.viewType.toLowerCase() == 'grid') {
                    controllerInstance.filteredDocuments = genericDocuments;
                }
                else {
                    controllerInstance.filteredDocumentsForTableView[controllerInstance.myDocumentsConfigService.myDocConfig.documentCategoryIndex] = genericDocuments;
                    console.log("filtered documents table view", controllerInstance.filteredDocumentsForTableView[controllerInstance.myDocumentsConfigService.myDocConfig.documentCategoryIndex]);
                }
                controllerInstance.setWidgetReady();
            };
            this.refreshWidget = function (setLandingPage) {
                _this.resetMassiveDownloadParameters();
                _this.widgetConfig.status.mode = 'loading';
                _this.docTagsForSummary = undefined;
                _this.isGroupedBy = false;
                var viewType = _this.myDocumentsConfigService.myDocConfig.viewType;
                _this.resetNestedTable();
                _this.resetOrderParameter();
                if (!_this.myDocumentsConfigService.myDocConfig.remotePagination) {
                    _this.initWidgetWithFrontPagination(_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex, viewType);
                }
                else {
                    _this.initWidgetWithRemotePagination(_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex, viewType, setLandingPage);
                }
                _this.myDocumentsConfigService.myDocConfig.viewType = viewType;
            };
            this.getDocuments = function (serviceParams, callback) {
                var reportingIcaroDocuments;
                _this.myDocumentsService.getGenericDocuments(serviceParams).then(function (genericDocuments) {
                    if (!_this.onboardingContractPolling && _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex === 'summaryPdf') {
                        if (serviceParams.page == 1) {
                            _this.myDocumentsService.getReportingDocumentsIcaro.save({}, {
                                "paginator": { "pageSize": 999, "current": 0 },
                                "read": null,
                                "startDate": "",
                                "endDate": "",
                                "flowCodes": Object.keys(ReportFileNameMap.map)
                            }, function (response) {
                                reportingIcaroDocuments = response.result;
                                callback(_this.applyPorcataForGrid(genericDocuments), _this, reportingIcaroDocuments);
                            });
                        }
                        else {
                            callback(_this.applyPorcataForGrid(genericDocuments), _this, []);
                        }
                    }
                    else {
                        if (!!_this.onboardingContractPolling && genericDocuments.length > 0) {
                            _this.$interval.cancel(_this.onboardingContractPolling);
                        }
                        callback(_this.applyPorcataForGrid(genericDocuments), _this);
                    }
                }, function (error) {
                    _this.handlerWidgetError(error);
                });
            };
            this.isTouch = function () {
                return (Modernizr.touch && (typeof cordova != 'undefined'));
            };
            this.isSmartphone = function () {
                if (md)
                    return md.phone();
            };
            this.isIphone = function () {
                if (md)
                    return md.is('iPhone');
            };
            this.downloadDocument = function (document, tag, key) {
                var url = _this.myDocumentsService.getDownloadDocumentUrl(document, true, key);
                if (document.isIcaroDocument) {
                    _this.myDocumentsService.downloadIcaroDocument(document.id).then(function (response) {
                        if (!_this.isTouch()) {
                            saveAs(response.data, "" + document.title + ".pdf");
                        }
                        else {
                            var file = new Blob([response.data], { type: 'application/pdf;charset=utf-8' });
                            var fileURL = URL.createObjectURL(file);
                            showFile(fileURL, null, null, null);
                        }
                    });
                    if (!!!document.isWidgetDownload)
                        _this.toggleTagForDoc(document, tag);
                }
                else {
                    _this.$window.open(url, "_blank");
                    console.log("download doc", document);
                    _this.toggleTagForDoc(document, tag);
                }
            };
            this.downloadKiidDocument = function (isin) {
                var url = "https://documenti-fondi.azimut.it/5_" + isin + ".pdf";
                _this.$window.open(url, "_blank");
            };
            this.downloadAllDocument = function (key, isPdobTable) {
                var group = !isPdobTable ? _this.getGroupedElements()[key] : _this.getGroupedPdobElements()[key];
                if (group.documentsList.length < 50) {
                    var groupId = encodeURIComponent(group.key);
                    var url = "" + apiPrefix + "/documents/" + (!isPdobTable ? 'downloadGroupedDocumentByUser' : 'downloadGroupedPdobDocumentByUser') + "?docCategory=" + _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex + "&groupBy=" + _this.groupByDocumentLabel + "&groupId=" + groupId;
                    console.log("downloadAllDocument", url);
                    _this.$window.open(url, "_blank");
                    group.documentsList.forEach(function (document) { return _this.toggleTagForDoc(document, 'unread'); });
                }
            };
            this.openPdf = function (document, tag, event) {
                var url = _this.myDocumentsService.getDownloadDocumentUrl(document, true);
                if (document.isIcaroDocument) {
                    if (!_this.isTouch()) {
                        _this.myDocumentsService.downloadIcaroDocument(document.id).then(function (response) {
                            saveAs(response.data, "" + document.title + ".pdf");
                        });
                    }
                    else {
                        var fileURL = "" + window.location.protocol + "//" + window.location.host + "/api/icaro/communication/download/" + document.id;
                        showFile(fileURL, null, null, null);
                    }
                }
                else {
                    console.log("openPdf", url);
                    showFile(url, 'pdf', '', event.target);
                }
                _this.toggleTagForDoc(document, tag);
            };
            this.openKiidPdf = function (isin, event) {
                var url = "https://documenti-fondi.azimut.it/5_" + isin + ".pdf";
                showFile(url, '', '', event.target);
            };
            this.openKidPdf = function (url, event) {
                showFile(url, '', '', event.target);
            };
            this.resetMassiveDownloadParameters = function () {
                _this.documentSelectedGroup = [];
                _this.rtoAcknowledgmentMap = {};
                _this.counterPdf = 0;
            };
            this.downloadDocumentMassive = function () {
                if (_this.documentSelectedGroup.length && _this.counterPdf <= _this.downloadPdfLimit && _this.isGroupedBy) {
                    var pathUrl = _this.isPdobDocumentsSelected ? 'downloadMultiGroupedPdobDocumentByUser' : 'downloadMultiGroupedDocumentByUser';
                    var encodedKeys = _this.documentSelectedGroup.map(function (key) { return encodeURIComponent(key); });
                    var documentMassiveList = encodedKeys.join('&groupIdList=');
                    var url = "" + apiPrefix + "/documents/" + pathUrl + "?docCategory=" + _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex + "&groupBy=" + _this.groupByDocumentLabel + "&groupIdList=" + documentMassiveList;
                    console.log('DOWN URL', url);
                    _this.$window.open(url, "_blank");
                    _this.documentSelectedGroup.forEach(function (groupKey) { return _this.filteredDocuments.find(function (groupDocument) { return groupDocument.key === groupKey; }).documentsList.forEach(function (document) { return _this.toggleTagForDoc(document, 'unread'); }); });
                }
                if (_this.documentSelectedGroup.length && _this.counterPdf <= _this.downloadPdfLimit && !_this.isGroupedBy) {
                    var documentMassiveList = _this.documentSelectedGroup.join(',');
                    var url = "" + apiPrefix + "/documents/downloadMultiDocument?docCategory=" + _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex + "&idList=" + documentMassiveList;
                    _this.$window.open(url, "_blank");
                    _this.documentSelectedGroup.forEach(function (groupKey) { return _this.filteredDocuments.find(function (groupDocument) { return groupDocument.key === groupKey; }).documentsList.forEach(function (document) { return _this.toggleTagForDoc(document, 'unread'); }); });
                }
            };
            this.addGroupDocumentsOnDownloadList = function (key, isPdobTable) {
                var doc = !isPdobTable ? _this.getGroupedElements()[key] : _this.getGroupedPdobElements()[key];
                if (_this.documentSelectedGroup.indexOf(doc.key) != -1) {
                    _this.documentSelectedGroup = _this.documentSelectedGroup.filter(function (item) { return item !== doc.key; });
                    _this.counterPdf -= parseInt(doc.documentsList.length);
                    _this.isPdobDocumentsSelected = !_this.documentSelectedGroup.length ? false : _this.isPdobDocumentsSelected;
                }
                else {
                    if (!_this.documentSelectedGroup.length && isPdobTable)
                        _this.isPdobDocumentsSelected = true;
                    if (isPdobTable === _this.isPdobDocumentsSelected) {
                        _this.documentSelectedGroup.push(doc.key);
                        _this.counterPdf += parseInt(doc.documentsList.length);
                    }
                }
            };
            this.addDocumentOnDownloadList = function (key, index) {
                var doc = _this.getFilteredTableDocumentsForView(key)[index].id;
                if (_this.documentSelectedGroup.indexOf(doc) != -1) {
                    _this.documentSelectedGroup = _this.documentSelectedGroup.filter(function (item) { return item !== doc; });
                    _this.counterPdf--;
                }
                else {
                    _this.documentSelectedGroup.push(doc);
                    _this.counterPdf++;
                }
            };
            this.enableDownloadMassive = function () {
                var cat = !!_this.myDocumentsConfigService.myDocConfig && _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex;
                return (cat === 'contract' || cat === 'expired' || cat === 'disposition') && _this.myDocumentsService.widgetType === MyDocumentsController.ADVISOR_WIDGET_NAME;
            };
            this.disableCheckbox = function (key, checkboxModel, isPdobTable) {
                var doc = !isPdobTable ? _this.getGroupedElements() && _this.getGroupedElements()[key] : _this.getGroupedPdobElements() && _this.getGroupedPdobElements()[key];
                return !!doc && (doc.documentsList.length + _this.counterPdf) > _this.downloadPdfLimit && !_this.rtoAcknowledgmentMap[doc.key][checkboxModel] && (_this.documentSelectedGroup.length > 0 && isPdobTable === _this.isPdobDocumentsSelected);
            };
            this.toggleTagForDoc = function (document, tag) {
                var index, pos;
                pos = document.tags.indexOf(tag);
                _this.toggleTagForDocInFrontPagination(document, pos);
                _this.updateBadges(document, tag, pos);
            };
            this.updateBadges = function (document, tag, pos) {
                if (!pos) {
                    _this.badges.filter(function (item) { return item.documentCategory == document.topic; })[0].documentTypes.unread--;
                }
            };
            this.toggleTagForDocument = function (documents, documentToUpdate, pos) {
                angular.forEach(documents, function (val, key) {
                    if (val.id == documentToUpdate.id) {
                        if (pos === 0) {
                            val.tags.splice(pos, 1);
                        }
                    }
                });
            };
            this.toggleTagForDocInFrontPagination = function (document, pos) {
                angular.forEach(_this.filteredDocuments, function (val, key) {
                    if (val.documentsList !== 'undefined') {
                        _this.toggleTagForDocument(val.documentsList, document, pos);
                    }
                });
                _this.toggleTagForDocument(_this.filteredDocuments, document, pos);
                _this.toggleTagForDocument(_this.filteredDocumentsForTableView[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex], document, pos);
            };
            this.changeView = function (viewType) {
                var result;
                _this.myDocumentsConfigService.myDocConfig.viewType = viewType;
                _this.myDocumentsConfigService.myDocConfig.defaultView = viewType;
                _this.reverseOrder = [];
                _this.isGroupedBy = false;
                _this.resetNestedTable();
                if (_this.myDocumentsConfigService.myDocConfig.remotePagination) {
                    _this.reverseOrderForBESorting = {};
                    _this.resetOrderParameter();
                    _this.genericDocumentsPaginatedServiceParams = new serviceData.GenericDocumentsPaginatedServiceParams();
                    _this.handleGroupingView();
                }
                else {
                    _this.initDefaultGrouping();
                    if (_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex != 'unread') {
                        _this.filteredDocuments = _this.$filter('filter')(_this.documentList, { topic: _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex });
                    }
                    _this.indexOrderByFilter = 0;
                    var isGridView = (_this.myDocumentsConfigService.myDocConfig.viewType) ? _this.myDocumentsConfigService.myDocConfig.viewType.toLowerCase() == 'grid' : true;
                    _this.changeOrderByFilter(_this.indexOrderByFilter, isGridView, _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex, _this.myDocumentsConfigService.myDocConfig.orderByListIndex[_this.indexOrderByFilter], true);
                }
            };
            this.showDetails = exports.showDetails;
            this.changeOrderByFilter = exports.changeOrderByFilter;
            this.setOrderByList = function () {
                var documentCategoryIndex = _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex;
                if (documentCategoryIndex == '' || documentCategoryIndex == 'unread' || _.isUndefined(documentCategoryIndex)) {
                }
                else {
                    if (!_this.isOwuiTab())
                        _this.myDocumentsConfigService.myDocConfig.orderByListIndex = _this.myDocumentsConfigService.myDocConfig.documentCategories[documentCategoryIndex].gridViewConf.orderByList;
                }
            };
            this.changePage = function (index, extraParameter) {
                var paginationIstance = extraParameter[0];
                var documentCategory = extraParameter[1];
                if (documentCategory !== void 0) {
                    paginationIstance[documentCategory].changePage(index);
                    _this.partialPageForTabView = 0;
                    _this.totalForTabView = 0;
                    angular.forEach(_this.myDocumentsConfigService.myDocConfig.documentCategories, function (val, key) {
                        _this.partialPageForTabView = _this.partialPageForTabView + _this.paginationForTabView[key].itemsInPage;
                        var size = (_this.paginationForTabView[key].size.length != undefined) ? _this.paginationForTabView[key].size.length : _this.paginationForTabView[key].size;
                        _this.totalForTabView = _this.totalForTabView + size;
                    });
                }
                else {
                    paginationIstance.changePage(index);
                }
            };
            this.changePageRemote = function (index, extraParameter) {
                var paginationIstance = extraParameter[0];
                var documentCategory = extraParameter[1];
                _this.widgetConfig.status.mode = "waiting";
                if (documentCategory !== void 0) {
                    paginationIstance[documentCategory].changePage(index);
                    _this.partialPageForTabView = 0;
                    _this.totalForTabView = 0;
                    angular.forEach(_this.myDocumentsConfigService.myDocConfig.documentCategories, function (val, key) {
                        _this.partialPageForTabView = _this.partialPageForTabView + _this.paginationForTabView[key].itemsInPage;
                        var size = (_this.paginationForTabView[key].size.length != undefined) ? _this.paginationForTabView[key].size.length : _this.paginationForTabView[key].size;
                        _this.totalForTabView = _this.totalForTabView + size;
                    });
                    _this.getPaginatedDocuments(documentCategory, index);
                }
                else {
                    paginationIstance.changePage(index);
                    _this.getPaginatedDocuments(_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex, index);
                }
            };
            this.changePageForGroupedTableWithRemotePagination = function (index) {
                _this.paginationForGroupedTable.changePage(index);
                _this.getPaginatedGroupedDocuments(index);
            };
            this.changePageForGroupedPdobTableWithRemotePagination = function (index) {
                _this.paginationForGroupedPdobTable.changePage(index);
                _this.getPaginatedGroupedPdobDocuments(index);
            };
            this.resetPagination = function () {
                if (_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex == '') {
                    angular.forEach(_this.paginationForTabView, function (val, key) {
                        val.changePage(1);
                    });
                    angular.forEach(_this.paginationForGridView, function (val, key) {
                        val.changePage(1);
                    });
                }
                else {
                    if (!!_this.paginationForTabView[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex]) {
                        _this.paginationForTabView[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex].changePage(1);
                    }
                    else {
                        console.log("Warning: pagination for table view for " + _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex + "is undefined!!");
                    }
                    if (!!_this.paginationForGridView[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex]) {
                        _this.paginationForGridView[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex].changePage(1);
                    }
                    else {
                        console.log("Warning: pagination for grid view for " + _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex + "is undefined!!");
                    }
                }
            };
            this.searchAdvisorFilter = function (tabTitle) {
                _this.isAdvisorSearch = true;
                _this.resetMassiveDownloadParameters();
                _this.resetOrderParameter();
                _this.resetNestedTable();
                _this.genericDocumentsPaginatedServiceParams.setSort(null);
                if (tabTitle === 'contract') {
                    _this.handleGroupingView();
                }
                else {
                    _this.resetNestedTable();
                    _this.groupedTableService.reverseOrderForNestedTable = [[]];
                    _this.changeGroupWithRemotePagination(_this.groupedTableService.selectedGroup);
                }
            };
            this.changeTabFilter = function (tabTitle) {
                if (_this.isAdvisorDocWidget()) {
                    if (tabTitle !== MyDocumentsController.ADVISOR_COMMUNICATION && tabTitle !== MyDocumentsController.ADVISOR_REPORTING) {
                        _this.resetAdvisorFilter();
                        _this.myDocumentsService.getDocumentType(tabTitle).then(function (response) {
                            _this.docTypeList = response;
                        }, function (error) {
                        });
                    }
                }
                if (_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex === tabTitle) {
                    return;
                }
                else {
                    _this.resetMassiveDownloadParameters();
                    if (!!tabTitle && tabTitle != 'unread') {
                        _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex = tabTitle;
                    }
                    else {
                        _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex = '';
                    }
                    _this.resetSettingsOnChangeTab();
                    _this.resetOrderParameter();
                    _this.genericDocumentsPaginatedServiceParams.setSort(null);
                    if (_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex !== MyDocumentsController.TAB_COMMUNICATION_NAME && _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex !== MyDocumentsController.ADVISOR_COMMUNICATION && _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex !== MyDocumentsController.ADVISOR_REPORTING) {
                        if (_this.myDocumentsConfigService.myDocConfig.remotePagination) {
                            _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex = tabTitle;
                            _this.handleGroupingView();
                        }
                        else {
                            _this.initDefaultGrouping();
                            _this.indexOrderByFilter = 0;
                            var isGridView = (_this.myDocumentsConfigService.myDocConfig.viewType) ? _this.myDocumentsConfigService.myDocConfig.viewType.toLowerCase() == 'grid' : true;
                            _this.filteredDocuments = _this.$filter('filter')(_this.documentList, { topic: _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex });
                            _this.changeOrderByFilter(_this.indexOrderByFilter, isGridView, _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex, _this.myDocumentsConfigService.myDocConfig.orderByListIndex[_this.indexOrderByFilter], true);
                        }
                    }
                    else {
                        _this.widgetConfig.status.mode = 'waiting';
                        if (tabTitle === MyDocumentsController.ADVISOR_COMMUNICATION) {
                            _this.widgetProp = {
                                'is-touch': _this.isTouch(),
                                'is-advisor-communication': true,
                                'is-advisor-reporting': false,
                            };
                        }
                        if (tabTitle === MyDocumentsController.ADVISOR_REPORTING) {
                            _this.widgetProp = {
                                'is-touch': _this.isTouch(),
                                'is-advisor-communication': false,
                                'is-advisor-reporting': true,
                            };
                        }
                        var widgetComm = document.getElementsByTagName(OwuiWidgetConstant.OwuiWidgetMap[0 /* WIDGET_COMMUNICATION */].widgetTag)[0];
                        if (!!widgetComm)
                            _this.setWidgetReady();
                    }
                }
            };
            this.applyFilter = function (filter) {
                _this.documentFilterIndex = filter;
                _this.resetSettingsOnChangeTab();
                if (_this.myDocumentsConfigService.myDocConfig.remotePagination) {
                    var defaultGrouping = _this.myDocumentsConfigService.myDocConfig.documentCategories[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex].listViewConf.defaultGrouping;
                    if (_this.myDocumentsConfigService.myDocConfig.viewType == 'list' && defaultGrouping) {
                        _this.handlerChangeGroup(defaultGrouping);
                    }
                    else {
                        _this.getPaginatedDocuments(_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex, 1);
                    }
                }
                else {
                    var categoryIndex = _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex;
                    if (categoryIndex != 'unread') {
                        _this.filteredDocuments = _this.$filter('filter')(_this.documentList, { topic: categoryIndex });
                        _this.filteredDocuments = _this.$filter('dwxMyDocumentsFilterDocuments')(_this.filteredDocuments, (filter != '') ? "attributes." + filter : "attributes");
                        if (categoryIndex != '') {
                            var documentListFiltered = _this.$filter('filter')(_this.documentList, { topic: categoryIndex });
                            _this.filteredDocumentsForTableView[categoryIndex] = _this.$filter('dwxMyDocumentsFilterDocuments')(documentListFiltered, (filter != '') ? "attributes." + filter : "attributes");
                            _this.paginationForTabView[categoryIndex].updateItems(_this.filteredDocumentsForTableView[categoryIndex]);
                        }
                    }
                    _this.partialPageForTabView = _this.paginationForGridView['itemsInPage'];
                    if (_this.filteredDocuments.length > 0) {
                        (_this.groupedTableService.selectedGroup != '' && _this.myDocumentsConfigService.myDocConfig.documentCategories[categoryIndex].listViewConf.groupByList[_this.groupedTableService.selectedGroup]) ? _this.changeGroup(_this.groupedTableService.selectedGroup, true) : '';
                    }
                }
            };
            this.openRow = function (index) {
                _this.rowOpened[index] = !_this.rowOpened[index];
            };
            this.getPaginatedGroupedPdobDocuments = function (index, groupName) {
                _this.setWaitingState();
                var group = groupName || _this.genericGroupedDocumentsServiceParams.getGroupBy();
                var documentCategoryIndex = _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex;
                var tagList = _this.myDocumentsConfigService.myDocConfig.documentCategories[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex].tagsList;
                if (_this.documentFilterIndex === MyDocumentsController.ADVISOR_PERSONAL_DOC_TAB)
                    tagList = tagList.concat('toSign');
                var docTags = (tagList) ? tagList.concat(_this.documentFilterIndex) : [_this.documentFilterIndex];
                _this.genericGroupedDocumentsServiceParams.setDocCategory(documentCategoryIndex).setGroupBy(group).setPage(index).setSize(_this.setNumberItemsInPage()).setDocTags(docTags).setAdvisorSearchUserCode(_this.advisorSearchCustomerCode).setAdvisorSearchNominative(_this.advisorSearchNominative).setAdvisorSearchStartDate(_this.$filter('date')(_this.advisorSearchStartDate, 'dd/MM/yyyy')).setAdvisorSearchEndDate(_this.$filter('date')(_this.advisorSearchEndDate, 'dd/MM/yyyy')).setAdvisorSearchDocType(_this.advisorSearchDocType);
                var pdobDocParams = _this.genericGroupedDocumentsServiceParams;
                _this.myDocumentsService.getPaginatedGroupedPDOBDocuments(pdobDocParams).then(function (pdobDocumentsResponse) {
                    _this.pdbobDocuments = pdobDocumentsResponse.groupedDocuments;
                    _this.groupedTableService.lastGroupedItems[MyDocumentsController.PDOB_DOCUMENTS_CATEGORY] = {};
                    _this.groupedTableService.lastGroupedItems[MyDocumentsController.PDOB_DOCUMENTS_CATEGORY][group] = _this.applyPorcata(pdobDocumentsResponse.groupedDocuments);
                    _this.keyOfGroupedPDOBItems = Object.keys(_this.groupedTableService.lastGroupedItems[MyDocumentsController.PDOB_DOCUMENTS_CATEGORY][group]);
                    if (!_this.paginationForGroupedPdobTable) {
                        _this.paginationForGroupedPdobTable = new _this.remotePaginationService([], (_this.setNumberItemsInPage()), 1);
                        _this.paginationForGroupedPdobTable.updateItems(pdobDocumentsResponse.groupsNumber);
                    }
                    _this.setWidgetReady();
                });
            };
            this.getPaginatedGroupedDocuments = function (index, groupName) {
                _this.setWaitingState();
                var group = groupName || _this.genericGroupedDocumentsServiceParams.getGroupBy();
                var documentCategoryIndex = _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex;
                var tagList = _this.myDocumentsConfigService.myDocConfig.documentCategories[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex].tagsList;
                if (_this.documentFilterIndex === MyDocumentsController.ADVISOR_PERSONAL_DOC_TAB)
                    tagList = tagList.concat('toSign');
                var docTags = (tagList) ? tagList.concat(_this.documentFilterIndex) : [_this.documentFilterIndex];
                _this.genericGroupedDocumentsServiceParams.setDocCategory(documentCategoryIndex).setGroupBy(group).setPage(index).setSize(_this.setNumberItemsInPage()).setDocTags(docTags).setAdvisorSearchUserCode(_this.advisorSearchCustomerCode).setAdvisorSearchNominative(_this.advisorSearchNominative).setAdvisorSearchStartDate(_this.$filter('date')(_this.advisorSearchStartDate, 'dd/MM/yyyy')).setAdvisorSearchEndDate(_this.$filter('date')(_this.advisorSearchEndDate, 'dd/MM/yyyy')).setAdvisorSearchDocType(_this.advisorSearchDocType);
                _this.myDocumentsService.getPaginatedGroupedDocuments(_this.genericGroupedDocumentsServiceParams).then(function (response) {
                    _this.filteredDocuments = _this.applyPorcata(response.groupedDocuments);
                    _this.groupedTableService.lastGroupedItems[documentCategoryIndex] = {};
                    _this.groupedTableService.lastGroupedItems[documentCategoryIndex][group] = _this.applyPorcata(response.groupedDocuments);
                    _this.keyOfGroupedItems = Object.keys(_this.groupedTableService.lastGroupedItems[documentCategoryIndex][group]);
                    if (!_this.paginationForGroupedTable) {
                        _this.paginationForGroupedTable = new _this.remotePaginationService([], (_this.setNumberItemsInPage()), 1);
                        _this.paginationForGroupedTable.updateItems(response.groupsNumber);
                    }
                    response.groupedDocuments.forEach(function (item, index) {
                        var currentIndexUnreadFound = false;
                        item.documentsList.forEach(function (document) {
                            if (document.tags[0] === 'unread') {
                                currentIndexUnreadFound = true;
                                _this.expandTable(index);
                            }
                            else {
                                if (_this.expanded[index] === true && currentIndexUnreadFound === false) {
                                    currentIndexUnreadFound = false;
                                    _this.expandTable(index);
                                }
                            }
                        });
                    });
                    _this.setWidgetReady();
                }, function (error) {
                    _this.setWidgetReady();
                });
            };
            this.openAFBModalMobile = function (event) {
                _this.myDocumentsService.getAFBUrl().then(function (response) {
                    showFile(response.url, '', '', event.target);
                });
            };
            this.openAFBModal = function () {
                var width = 850;
                var height = 600;
                var left = (screen.width - width) / 2;
                var top = (screen.height - height) / 2;
                var opts = "resizable=yes,";
                opts += "toolbar=yes,";
                opts += "scrollbars=yes,";
                opts += "copyhistory=no,";
                opts += "location=yes,";
                opts += "left=" + left + ",";
                opts += "top=" + top + ",";
                opts += "width=" + width + ",";
                opts += "height=" + height + "'";
                var modal = window.open(undefined, 'Iris', opts);
                _this.myDocumentsService.getAFBUrl().then(function (response) {
                    modal.location = response.url;
                });
            };
            this.applyPrivacyPatch = function (docs) {
                var filteredArray = [];
                var now = new Date();
                var expireDate30 = new Date(2017, 0, 30, 20, 0, 0, 0);
                var expireDate31 = new Date(2017, 0, 31, 20, 0, 0, 0);
                var convertedDocumentExpirationDate = new Date();
                for (var i = 0; i < docs.length; i++) {
                    if (docs[i].key.indexOf('PRV_') > -1 && docs[i].documentsList[0].topic.indexOf('signRoom') > -1) {
                        convertedDocumentExpirationDate = new Date(parseInt(docs[i].documentsList[0].attributes.expirationDate));
                        if (now >= expireDate31) {
                            if (parseInt(docs[i].groupAdditionalInfo.documentsToSign) == 1) {
                                _this.badges[0].documentTypes["toSign"]--;
                            }
                        }
                        else if (convertedDocumentExpirationDate >= expireDate30 && convertedDocumentExpirationDate <= expireDate31 && now >= expireDate30 && now <= expireDate31) {
                            if (parseInt(docs[i].groupAdditionalInfo.documentsToSign) == 1) {
                                _this.badges[0].documentTypes["toSign"]--;
                            }
                        }
                        else {
                            filteredArray.push(docs[i]);
                        }
                    }
                    else {
                        filteredArray.push(docs[i]);
                    }
                }
                return filteredArray;
            };
            this.applyPorcataForGrid = function (docs) {
                var filteredArray = [];
                var now = new Date();
                var expireDate30 = new Date(2017, 0, 30, 20, 0, 0, 0);
                var expireDate31 = new Date(2017, 0, 31, 20, 0, 0, 0);
                var convertedDocumentExpirationDate = new Date();
                for (var i = 0; i < docs.length; i++) {
                    if (typeof docs[i].attributes.practiceId != 'undefined' && docs[i].attributes.practiceId.indexOf('PRV_') > -1 && docs[i].topic.indexOf('signRoom') > -1) {
                        convertedDocumentExpirationDate = new Date(parseInt(docs[i].attributes.expirationDate));
                        if (!(now >= expireDate31) && !(convertedDocumentExpirationDate >= expireDate30 && convertedDocumentExpirationDate <= expireDate31 && now >= expireDate30 && now <= expireDate31)) {
                            filteredArray.push(docs[i]);
                        }
                    }
                    else {
                        filteredArray.push(docs[i]);
                    }
                }
                return filteredArray;
            };
            this.applyPorcata = function (groupedDocs) {
                for (var i = 0; i < groupedDocs.length; i++) {
                    if (groupedDocs[i].key.indexOf('PRV') > -1) {
                        groupedDocs[i].key = groupedDocs[i].key.replace(/simulazione/g, 'privacy');
                        groupedDocs[i].key = groupedDocs[i].key.replace(/SIMULAZIONE/g, 'PRIVACY');
                        groupedDocs[i].documentsList[0].attributes.processType = "PRIVACY";
                    }
                }
                return _this.applyPrivacyPatch(groupedDocs);
            };
            this.changeGroupWithRemotePagination = function (groupName) {
                if (_this.documentFilterIndex === MyDocumentsController.ADVISOR_PERSONAL_DOC_TAB)
                    groupName = 'practiceId';
                _this.groupedTableService.selectedGroup = groupName;
                if (groupName != 'document') {
                    _this.reverseOrderForBESorting = {};
                    _this.paginationForGroupedTable = undefined;
                    _this.genericGroupedDocumentsServiceParams = new serviceData.GenericGroupedDocumentsServiceParams();
                    if (_this.myDocumentsService.widgetType === MyDocumentsController.ADVISOR_WIDGET_NAME) {
                        _this.setReverseOrderForBESorting('KEY');
                        _this.genericGroupedDocumentsServiceParams.setSort(_this.createSortParameter('KEY', _this.reverseOrderForBESorting['KEY'].order));
                    }
                    _this.isGroupedBy = true;
                    _this.groupByDocumentLabel = groupName;
                    _this.setGroupedTableColumn(groupName);
                    if (_this.showPdobDocuments()) {
                        _this.setWaitingState();
                        var tagList = _this.myDocumentsConfigService.myDocConfig.documentCategories[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex].tagsList;
                        var docTags = (tagList) ? tagList.concat(_this.documentFilterIndex) : [_this.documentFilterIndex];
                        _this.genericGroupedDocumentsServiceParams.setDocCategory(_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex).setGroupBy(groupName).setPage(1).setSize(_this.setNumberItemsInPage()).setDocTags(docTags).setAdvisorSearchUserCode(_this.advisorSearchCustomerCode).setAdvisorSearchNominative(_this.advisorSearchNominative).setAdvisorSearchStartDate(_this.$filter('date')(_this.advisorSearchStartDate, 'dd/MM/yyyy')).setAdvisorSearchEndDate(_this.$filter('date')(_this.advisorSearchEndDate, 'dd/MM/yyyy')).setAdvisorSearchDocType(_this.advisorSearchDocType);
                        var pdobDocParams = _this.genericGroupedDocumentsServiceParams;
                        _this.myDocumentsService.getPaginatedGroupedPDOBDocuments(pdobDocParams).then(function (pdobDocumentsResponse) {
                            _this.pdbobDocuments = pdobDocumentsResponse.groupedDocuments;
                            _this.groupedTableService.lastGroupedItems[MyDocumentsController.PDOB_DOCUMENTS_CATEGORY] = {};
                            _this.groupedTableService.lastGroupedItems[MyDocumentsController.PDOB_DOCUMENTS_CATEGORY][groupName] = _this.applyPorcata(pdobDocumentsResponse.groupedDocuments);
                            _this.keyOfGroupedPDOBItems = Object.keys(_this.groupedTableService.lastGroupedItems[MyDocumentsController.PDOB_DOCUMENTS_CATEGORY][groupName]);
                            if (!_this.paginationForGroupedPdobTable) {
                                _this.paginationForGroupedPdobTable = new _this.remotePaginationService([], (_this.setNumberItemsInPage()), 1);
                                _this.paginationForGroupedPdobTable.updateItems(pdobDocumentsResponse.groupsNumber);
                            }
                            _this.setWidgetReady();
                        }).finally(function () {
                            _this.getPaginatedGroupedDocuments(1, groupName);
                        });
                    }
                    else {
                        _this.getPaginatedGroupedDocuments(1, groupName);
                    }
                }
                else {
                    _this.isGroupedBy = false;
                    _this.groupByDocumentLabel = "document";
                    _this.getPaginatedDocuments(_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex, 1);
                }
            };
            this.setGroupedTableColumn = function (groupName) {
                var documentCategoryIndex = _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex;
                var groupColumns = _this.myDocumentsConfigService.myDocConfig.documentCategories[documentCategoryIndex].listViewConf.groupByList[groupName].groupColumns;
                if (documentCategoryIndex == 'statement') {
                    _this.fieldForGroupedTable = groupColumns;
                }
                else {
                    _this.enableDownloadAll = _this.myDocumentsService.widgetType === MyDocumentsController.ADVISOR_WIDGET_NAME;
                    _this.fieldForGroupedTable = (groupColumns && groupColumns.length > 0 && !_this.isAdvisorDocumentsTab()) ? groupColumns : ['documents_length'];
                }
            };
            this.changeGroup = function (groupName, unlockWidget) {
                var deferred = _this.$q.defer();
                _this.groupedTableService.selectedGroup = groupName;
                if (groupName != 'document') {
                    _this.paginationForGroupedTable = new _this.paginationService([], _this.setNumberItemsInPage(), 1);
                    _this.fieldForGroupedTable[0] = groupName;
                    _this.isGroupedBy = true;
                    _this.groupByDocumentLabel = groupName;
                    var originalLastGroupedItem = _this.originalLastGroupedItems[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex];
                    if (originalLastGroupedItem != undefined && originalLastGroupedItem[groupName]) {
                        _this.cacheGroupedItems(groupName);
                    }
                    else {
                        _this.widgetConfig.status.mode = 'loading';
                        _this.myDocumentsService.getGroupedDocuments(_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex, groupName).then(function (groupedDocuments) {
                            _this.getGroupedDocumentsCallback(groupName, groupedDocuments, unlockWidget);
                            deferred.resolve();
                        }, function (error) {
                            _this.handlerWidgetError(error);
                            deferred.reject();
                        });
                    }
                }
                else {
                    _this.isGroupedBy = false;
                    _this.groupByDocumentLabel = "document";
                    deferred.resolve();
                }
                _this.resetOrderParameter();
                return deferred.promise;
            };
            this.getGroupedDocumentsCallback = function (groupName, groupedDocuments, unlockWidget) {
                if (_this.originalLastGroupedItems[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex]) {
                    _this.originalLastGroupedItems[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex][groupName] = groupedDocuments;
                }
                else {
                    _this.originalLastGroupedItems[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex] = [];
                    _this.originalLastGroupedItems[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex][groupName] = groupedDocuments;
                }
                _this.groupedTableService.lastGroupedItems = _this.originalLastGroupedItems;
                _this.keyOfGroupedItems = Object.keys(_this.groupedTableService.lastGroupedItems[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex][groupName]);
                _this.paginationForGroupedTable.updateItems(_this.keyOfGroupedItems);
                if (_this.myDocumentsConfigService.myDocConfig.orderByListIndex[0] != undefined && _this.myDocumentsConfigService.myDocConfig.orderByListIndex[0].title == groupName) {
                    _this.changeOrderFilterForGroupedTable(0, _this.myDocumentsConfigService.myDocConfig.orderByListIndex[0].ascending);
                }
                if (unlockWidget) {
                    _this.setWidgetReady();
                }
            };
            this.cacheGroupedItems = function (groupName) {
                _this.groupedTableService.lastGroupedItems = [];
                _this.groupedTableService.lastGroupedItems[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex] = [];
                _this.groupedTableService.lastGroupedItems[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex][groupName] = {};
                for (var val in _this.originalLastGroupedItems[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex][groupName]) {
                    var filtered = _this.$filter('dwxMyDocumentsFilterDocuments')(_this.originalLastGroupedItems[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex][groupName][val], (_this.documentFilterIndex != '') ? "attributes." + _this.documentFilterIndex : "attributes");
                    if (filtered != undefined && filtered.length > 0) {
                        _this.groupedTableService.lastGroupedItems[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex][groupName][val] = {};
                        _this.groupedTableService.lastGroupedItems[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex][groupName][val] = filtered;
                    }
                }
                _this.keyOfGroupedItems = Object.keys(_this.groupedTableService.lastGroupedItems[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex][groupName]);
                _this.paginationForGroupedTable.updateItems(_this.keyOfGroupedItems);
            };
            this.resetOrderParameter = function () {
                _this.indexOrderByFilter = 0;
                _this.reverseOrder = {};
                _this.groupedTableService.lastGroupedItems = [];
                _this.groupedTableService.reverseOrderForNestedTable = [[]];
                _this.indexFieldForGroupedTable = 0;
                _this.reverseOrderForGroupedTable = [];
            };
            this.changeOrderFilterForGroupedTable = function (indexField, ascending) {
                _this.paginationForGroupedTable.changePage(1);
                _this.groupedTableService.indexFieldForNestedTable = [];
                _this.groupedTableService.reverseOrderForNestedTable = [[]];
                if (typeof (ascending) !== 'undefined') {
                    _this.reverseOrderForGroupedTable[indexField] = !ascending;
                }
                else {
                    if (indexField === _this.indexFieldForGroupedTable && _this.reverseOrderForGroupedTable[indexField] !== void 0) {
                        _this.reverseOrderForGroupedTable[indexField] = !_this.reverseOrderForGroupedTable[indexField];
                    }
                    else {
                        _this.reverseOrderForGroupedTable[indexField] = false;
                    }
                }
                _this.indexFieldForGroupedTable = indexField;
                var lastGroupedItem = _this.groupedTableService.lastGroupedItems[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex];
                _this.keyOfGroupedItems = _this.$filter('dwxMyDocumentsSortObjectKey')(lastGroupedItem[_this.groupedTableService.selectedGroup], _this.reverseOrderForGroupedTable[_this.indexFieldForGroupedTable], _this.fieldForGroupedTable[_this.indexFieldForGroupedTable]);
            };
            this.initRemotePaginations = function (documentSummary) {
                angular.forEach(_this.myDocumentsConfigService.myDocConfig.documentCategories, function (val, key) {
                    var numberItemsInPageGridView = (val.gridViewConf.numberItemsInPage) ? val.gridViewConf.numberItemsInPage : _this.myDocumentsConfigService.myDocConfig.numberItemsInPageGridView;
                    var numberItemsInPageListView = (val.listViewConf.numberItemsInPage) ? val.listViewConf.numberItemsInPage : _this.myDocumentsConfigService.myDocConfig.numberItemsInPageListView;
                    try {
                        if (val.filtersList.length > 0) {
                            angular.forEach(documentSummary[0].documentTypes, function (filter, filterName) {
                                _this.createPagination(filterName, numberItemsInPageGridView, numberItemsInPageListView, filter);
                            });
                            _this.createPagination('', numberItemsInPageGridView, numberItemsInPageListView, documentSummary[0].totalDocuments);
                        }
                        var totalDocuments = documentSummary[_this.myDocumentsConfigService.myDocConfig.categories.indexOf(key)].totalDocuments;
                        _this.paginationForTabView[key] = new _this.remotePaginationService([], numberItemsInPageListView, 1);
                        _this.paginationForGridView[key] = new _this.remotePaginationService([], numberItemsInPageGridView, 1);
                        if (documentSummary[_this.myDocumentsConfigService.myDocConfig.categories.indexOf(key)] != undefined && totalDocuments) {
                            _this.paginationForTabView[key].updateItems(totalDocuments);
                            _this.paginationForGridView[key].updateItems(totalDocuments);
                            _this.partialPageForTabView = _this.partialPageForTabView + _this.paginationForTabView[key].itemsInPage;
                            var size = (_this.paginationForTabView[key].size.length != undefined) ? _this.paginationForTabView[key].size.length : _this.paginationForTabView[key].size;
                            _this.totalForTabView = _this.totalForTabView + size;
                        }
                    }
                    catch (e) {
                    }
                });
            };
            this.initPaginations = function () {
                angular.forEach(_this.myDocumentsConfigService.myDocConfig.documentCategories, function (val, key) {
                    var numberItemsInPageGridView = (val.gridViewConf.numberItemsInPage) ? parseInt(val.gridViewConf.numberItemsInPage) : _this.myDocumentsConfigService.myDocConfig.numberItemsInPageGridView;
                    var numberItemsInPageListView = (val.listViewConf.numberItemsInPage) ? parseInt(val.listViewConf.numberItemsInPage) : _this.myDocumentsConfigService.myDocConfig.numberItemsInPageListView;
                    _this.filteredDocumentsForTableView[key] = _this.$filter('filter')(_this.documentList, { topic: key });
                    if (_this.paginationForTabView[key] == undefined) {
                        _this.paginationForTabView[key] = new _this.paginationService([], numberItemsInPageListView, 1);
                        _this.paginationForTabView[key].updateItems(_this.filteredDocumentsForTableView[key]);
                        _this.paginationForGridView[key] = new _this.paginationService([], numberItemsInPageGridView, 1);
                        _this.paginationForGridView[key].updateItems(_this.filteredDocuments);
                        _this.partialPageForTabView = _this.partialPageForTabView + _this.paginationForTabView[key].itemsInPage;
                        var size = (_this.paginationForTabView[key].size.length != undefined) ? _this.paginationForTabView[key].size.length : _this.paginationForTabView[key].size;
                        _this.totalForTabView = _this.totalForTabView + size;
                    }
                });
                _this.$scope['paginationForTabView'] = _this.paginationForTabView;
            };
            this.goToSendDocumentForSign = function (key, $event, document) {
                if (_this.kiidAcknowledgmentMap[key] && !_this.disableCheckboxOnSigningState(key, document)) {
                    document = !!document ? document : _this.filteredDocuments[key].documentsList[0];
                    if (document.attributes.signableBundle)
                        _this.sendDocumentForSign(document, $event);
                }
            };
            this.goToSendDocumentForSignRto = function (key, $event) {
                if (_this.checkRTOAcknowledgment(key) && _this.filteredDocuments[key].documentsList[0].attributes.signableBundle) {
                    var bundle = _this.filteredDocuments[key].documentsList[0];
                    _this.sendDocumentForSign(bundle, $event);
                }
            };
            this.goToSendDocumentForSignAdvisorDoc = function (key, document, $event) {
                _this.sendDocumentForSign(document, $event);
            };
            this.sendDocumentForSign = function (document, $event) {
                if (document.attributes.signableBundle && !_this.disableCheckboxOnSigningState(null, document)) {
                    if (!!$event) {
                        $event.stopPropagation();
                    }
                    _this.widgetConfig.status.mode = 'waiting';
                    _this.myDocumentsService.retrieveBundleUrl.get({
                        bundleCode: document.attributes.bundleCode,
                        bundleContext: document.attributes.bundleContext
                    }, function (response) {
                        _this.setWidgetReady();
                        if (response.outcome === 'OK' && !!response.content) {
                            _this.showSignView(document, response);
                        }
                        else {
                            if (response.messageKey.indexOf("user_not_authenticated") >= 0)
                                return;
                            var title = _this.$filter('translate')('widget.signRoom.popUp.title.' + response.messageKey);
                            _this.showGenericSweetAlert(response.message, title, response.outcome.toLowerCase(), false, true);
                        }
                    }, function (error) {
                        _this.widgetConfig.status.mode = 'error';
                        _this.widgetConfig.status.message = "dashboard.error.connection_error";
                    });
                }
            };
            this.setKiidAcknowledgmentMap = function (key, document) {
                document = !!document ? document : _this.filteredDocuments[key].documentsList[0];
                _this.kiidAcknowledgmentMap[key] = document.attributes.documentState === 'SIGNING' || document.attributes.documentState === 'SIGNED';
            };
            this.setAcknowledgmentMap = function (key, document) {
                _this.acknowledgmentMap[document.id] = document.attributes.documentState === 'SIGNING';
            };
            this.showManlevaCheckbox = function (key, document) {
                return document.attributes.hasOwnProperty('manleva') && !document.attributes.manleva;
            };
            this.sendAcknowledgmentCheck = function (document, docType, key) {
                document = !!document ? document : _this.filteredDocuments[key].documentsList[0];
                if (((docType === 'MANDATORY' && _this.acknowledgmentMap[document.id]) || (docType === 'KIID' && _this.kiidAcknowledgmentMap[key])) && !_this.disableCheckboxOnSigningState(key, document)) {
                    docType = !_this.myDocumentsConfigService.myDocConfig.enablePolling ? docType : 'DIGITAL_SERVICES';
                    _this.myDocumentsService.saveBundleDocumentsViewed.save({}, {
                        bundleCode: document.attributes.bundleCode,
                        documentViewedType: docType
                    });
                }
            };
            this.showSignView = function (selectedDocument, response) {
                if (response.content.startSignatureInfo) {
                    _this.showSweetAlert(response.content.startSignatureInfo, selectedDocument, response);
                }
                else {
                    if (_this.isTouch()) {
                        var url = !!sessionStorage.getItem("sendOnboardingPopupParameter") ? response.content + '&onboarding_redirect' : response.content;
                        xyzmoSign({ xyzmoUrl: url });
                    }
                    else {
                        if (_this.myDocumentsService.widgetType !== 'signRoomForPromoter') {
                            _this.$window.location = response.content + "&redirectionUrl=" + encodeURIComponent(window.location.href);
                        }
                        else {
                            window.parent.postMessage({ name: 'MYAZIMUT_SIGN', url: response.content }, '*');
                        }
                    }
                }
            };
            this.showSweetAlert = function (text, document, response) {
                sweetAlert({
                    title: "",
                    text: text,
                    html: true,
                    type: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#385696",
                    confirmButtonText: "Ok",
                    cancelButtonText: "Annulla",
                    closeOnConfirm: false,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        if (_this.isTouch()) {
                            document.xyzmoUrl = response.content.xyzmoUrl;
                            xyzmoSign(document);
                        }
                        else {
                            _this.$window.location = response.content.xyzmoUrl + document.workstepId + '&LoadJavaApplet=0';
                        }
                    }
                    else {
                    }
                });
            };
            this.showGenericSweetAlert = function (text, title, type, showcancelbutton, reloadwidget) {
                sweetAlert({
                    title: title,
                    text: text,
                    html: true,
                    type: type,
                    showCancelButton: showcancelbutton,
                    confirmButtonColor: "#385696",
                    confirmButtonText: "Ok",
                    cancelButtonText: "Annulla",
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (reloadwidget) {
                        _this.refreshWidget(true);
                    }
                    else {
                    }
                });
            };
            this.expandTable = function (index, isPdobTable) {
                var pdobIndex = isPdobTable ? "pdob-" + index : index;
                _this.groupedTableService.nestedTableDocuments = _this.groupedTableService.nestedTableDocuments || {};
                var categoryIndex = isPdobTable ? MyDocumentsController.PDOB_DOCUMENTS_CATEGORY : _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex;
                var groupedItem = _this.groupedTableService.lastGroupedItems[categoryIndex][_this.groupedTableService.selectedGroup][index];
                var documentList = (!_this.myDocumentsConfigService.myDocConfig.remotePagination) ? groupedItem : groupedItem.documentsList;
                _this.groupedTableService.nestedTableDocuments[pdobIndex] = (!_this.myDocumentsConfigService.myDocConfig.remotePagination) ? groupedItem : groupedItem.documentsList;
                _this.innerPagination[pdobIndex] = new _this.paginationService([], _this.setNumberItemsInPage(), 1);
                _this.innerPagination[pdobIndex].updateItems(documentList);
                _this.expanded[pdobIndex] = !_this.expanded[pdobIndex];
                _this.groupedTableService.indexFieldForNestedTable[pdobIndex] = 0;
                _this.reverseOrderForGroupedTable[pdobIndex] = [];
                _this.selectedDocument = undefined;
                if (!_this.expanded[index] && !isPdobTable) {
                    _this.selectedDocumentProductsMap[index] = [];
                    _this.selectedDocumentESGProductsMap[index] = [];
                    _this.selectedDocumentProductsKidMap[index] = [];
                    _this.showProposal[index] = false;
                }
                else {
                    if (!isPdobTable)
                        _this.checkShowProposal(index);
                }
                _this.EventService.publish('resizeInnerTable', [], true);
                if ((!_this.groupedTableService.reverseOrderForNestedTable[pdobIndex] || _this.groupedTableService.reverseOrderForNestedTable[index].length == 0) && _this.myDocumentsService.widgetType === MyDocumentsController.ADVISOR_WIDGET_NAME) {
                    _this.groupedTableService.reverseOrderForNestedTable[pdobIndex] = [, , , true,];
                }
            };
            this.checkShowProposal = function (key) {
                if (_this.filteredDocuments[key].documentsList[0].topic === 'signRoom' && ((_this.isMaxProcess(key) && (!_this.isExecutor(key) || (_this.isExecutor(key) && _this.filteredDocuments[key].documentsList[0].attributes.documentState === 'SIGNING'))) || _this.isSimulaProcess(key) || _this.isRTOProcess(key) || !_this.isSignView())) {
                    _this.setPracticeProducts(key);
                    if (!_this.isRTOProcess(key))
                        _this.showProposal[key] = true;
                }
            };
            this.showKiid = function (productType) {
                return Kiid.types.indexOf(productType) > -1;
            };
            this.isExecutor = function (key) {
                var executor;
                _this.filteredDocuments[key].documentsList.filter(function (doc) {
                    if (doc.attributes.moduleType === 'RACC')
                        executor = doc.attributes.executor;
                });
                return executor;
            };
            this.isOnboardingProcess = function () {
                return _this.myDocumentsConfigService.myDocConfig.enablePolling;
            };
            this.isMaxProcess = function (key) {
                return !_this.showPdobDocuments() && ProcessTypeMax.types.indexOf(_this.filteredDocuments[key].documentsList[0].attributes.processTypeCode) > -1;
            };
            this.isSimulaProcess = function (key) {
                return !_this.showPdobDocuments() && ProcessTypeSimula.types.indexOf(_this.filteredDocuments[key].documentsList[0].attributes.processTypeCode) > -1;
            };
            this.isAdvisorDocuments = function (key) {
                return ProcessTypeAdvisorDocuments.types.indexOf(_this.filteredDocuments[key].documentsList[0].attributes.processTypeCode) > -1;
            };
            this.isSimulaOldProcess = function (key) {
                return _this.isSimulaProcess(key) && !_this.isSimulaAsMaxProcess(key);
            };
            this.isSimulaAsMaxProcess = function (key) {
                var result = [];
                if (_this.isSimulaProcess(key)) {
                    result = _this.filteredDocuments[key].documentsList.filter(function (document) {
                        return document.attributes.moduleType === MyDocumentsController.RESI_Z;
                    });
                }
                return result.length;
            };
            this.isModulesType = function (document) {
                return document.attributes.processTypeCode === 'MODULES';
            };
            this.getFirstDocument = function (key) {
                return {
                    attributes: { moduleType: (_this.isMaxProcess(key) ? 'RACC' : 'RESI_Z') }
                };
            };
            this.isRTOProcess = function (key) {
                var documentsList = _this.filteredDocuments && _this.filteredDocuments[key] && _this.filteredDocuments[key].documentsList;
                return documentsList && ProcessTypeRTO.types.indexOf(documentsList[0].attributes.processTypeCode) > -1;
            };
            this.isSignView = function () {
                return typeof (_this.myDocumentsConfigService.myDocConfig.showSignView) !== "undefined" ? _this.myDocumentsConfigService.myDocConfig.showSignView : true;
            };
            this.changeInnerTablePage = function (index, customerTableIndex) {
                _this.innerPagination[customerTableIndex].changePage(index);
            };
            this.disableCheckboxOnSigningState = function (key, document) {
                document = !!document ? document : _this.filteredDocuments[key].documentsList[0];
                return document.attributes.documentState !== 'TO_SIGN';
            };
            this.isPracticeSignable = function (key, document) {
                document = !!document ? document : _this.filteredDocuments[key].documentsList[0];
                return document.attributes.signableBundle;
            };
            this.hasAppropriateAccomplished = function (key) {
                var documentList = _this.filteredDocuments[key].documentsList;
                var ordDocument = documentList.filter(function (doc) { return doc.attributes.processMethodCode === 'ORD'; });
                var isOrdProcess = ordDocument.length > 0;
                if (!isOrdProcess)
                    return true;
                var notAppropriateLength = ordDocument.filter(function (doc) { return doc.attributes.products.filter(function (product) { return product.notAppropriate; }).length > 0; }).length;
                return _this.appropriateCheckMap.filter(function (check) { return check; }).length === notAppropriateLength;
            };
            this.checkRTOAcknowledgment = function (key) {
                var result = true;
                for (var acknowledgment in RTOSecondStepConfig.checkboxConf) {
                    result = !!_this.rtoAcknowledgmentMap[key] && result && _this.rtoAcknowledgmentMap[key][RTOSecondStepConfig.checkboxConf[acknowledgment].checkboxModel];
                }
                return result;
            };
            this.sortColumn = function (sortColumnName) {
                _this.setReverseOrderForBESorting(sortColumnName);
                _this.genericDocumentsPaginatedServiceParams.setSort(_this.createSortParameter(sortColumnName, _this.reverseOrderForBESorting[sortColumnName].order));
                _this.getPaginatedDocuments(_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex, 1);
            };
            this.setReverseOrderForBESorting = function (sortColumnName) {
                if (Object.keys(_this.reverseOrderForBESorting).indexOf(sortColumnName) != -1) {
                    _this.reverseOrderForBESorting[sortColumnName].order = (_this.reverseOrderForBESorting[sortColumnName].order == 'desc') ? 'asc' : 'desc';
                }
                else {
                    _this.reverseOrderForBESorting = {};
                    _this.reverseOrderForBESorting[sortColumnName] = {
                        order: 'asc'
                    };
                }
            };
            this.sortGrid = function (field, indexFilter) {
                _this.setWaitingState();
                _this.indexOrderByFilter = indexFilter;
                _this.selectedOrdering = field;
                var order = field.ascending ? 'asc' : 'desc';
                var title = field.title ? field.title : field;
                _this.genericDocumentsPaginatedServiceParams.setSort(_this.createSortParameter(title, order));
                _this.resetPagination();
                _this.getPaginatedDocuments(_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex, 1);
            };
            this.sortGroupedTable = function (column, field, isPdobTable) {
                var title = field;
                switch (title) {
                    case 'documents_length':
                        title = 'totalDocuments';
                        break;
                    case 'practiceId':
                        title = 'practice';
                        break;
                }
                _this.setReverseOrderForBESorting(title);
                _this.genericGroupedDocumentsServiceParams.setSort(_this.createSortParameter(title, _this.reverseOrderForBESorting[title].order));
                if (!isPdobTable)
                    _this.getPaginatedGroupedDocuments(1);
                if (isPdobTable)
                    _this.getPaginatedGroupedPdobDocuments(1);
            };
            this.createSortParameter = function (sortedField, order) {
                return sortedField + ',' + order;
            };
            this.setNumberItemsInPage = function () {
                var category = _this.myDocumentsConfigService.myDocConfig.documentCategories[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex];
                if (_this.myDocumentsConfigService.myDocConfig.viewType == 'grid') {
                    return (typeof (category) !== 'undefined') ? parseInt(_this.myDocumentsConfigService.myDocConfig.numberItemsInPageGridView) : null;
                }
                else {
                    return (typeof (category) !== 'undefined') ? parseInt(_this.myDocumentsConfigService.myDocConfig.numberItemsInPageListView) : null;
                }
            };
            this.setWaitingState = function () {
                if (_this.widgetConfig.status.mode !== 'loading')
                    _this.widgetConfig.status.mode = "waiting";
            };
            this.rejectProposal = function (document) {
                _this.setWaitingState();
                _this.myDocumentsService.rejectBundle.save({}, { bundleCode: document.attributes.bundleCode }, function (response) {
                    if (response.outcome === 'OK' && !!response.content) {
                        _this.refreshWidget();
                    }
                    else {
                        _this.showGenericSweetAlert(response.message, '', response.outcome.toLowerCase(), false, false);
                        _this.setWidgetReady();
                    }
                }, function () {
                    _this.widgetConfig.status.mode = 'error';
                    _this.widgetConfig.status.message = "dashboard.error.connection_error";
                });
            };
            this.goToNextStep = function (columnName, goToSign, document, event, key) {
                if (_this.acknowledgmentMap[document.id] && document.attributes.documentState === 'TO_SIGN' && document.attributes.signableBundle) {
                    switch (columnName) {
                        case 'rejectProposal':
                            _this.rejectProposal(document);
                            break;
                        case 'acceptProposal':
                            if (goToSign && document.attributes.signableBundle) {
                                _this.sendDocumentForSign(document, event);
                            }
                            else {
                                _this.setPracticeProducts(key);
                                console.log("selected document", _this.selectedDocument);
                                _this.showProposal[key] = true;
                            }
                    }
                }
            };
            this.goToNextStepSimula = function (columnName, document, event) {
                var manlevaCheck = _this.manlevaCheckMap[document.id] || !document.attributes.hasOwnProperty('manleva');
                if (_this.acknowledgmentMap[document.id] && manlevaCheck && document.attributes.documentState === 'TO_SIGN') {
                    if (document.attributes.signableBundle)
                        _this.sendDocumentForSign(document, event);
                }
            };
            this.setPracticeProducts = function (key) {
                _this.selectedDocumentProductsMap[key] = [];
                _this.selectedDocumentESGProductsMap[key] = [];
                _this.selectedDocumentProductsKidMap[key] = [];
                var raccomandation = _this.filteredDocuments[key].documentsList.filter(function (document) {
                    return document.attributes.moduleType === 'RACC';
                });
                if (raccomandation.length > 0) {
                    var raccomandationProducts = raccomandation[0].attributes.products;
                    _this.addProductByDisposition(raccomandationProducts, key);
                }
                else {
                    _this.filteredDocuments[key].documentsList.forEach(function (document, index) {
                        var dispositionProducts = document.attributes.products;
                        _this.addProductByDisposition(dispositionProducts, key);
                    });
                }
                console.log("selectedDocumentProductsKidMap", _this.selectedDocumentProductsKidMap);
            };
            this.addProductByDisposition = function (products, key) {
                if (!!products) {
                    products.forEach(function (product, index) {
                        var existingProduct = _this.selectedDocumentProductsMap[key].filter(function (selectedDocProduct) {
                            return selectedDocProduct.productIsin === product.productIsin;
                        });
                        if (!existingProduct.length && (product.productType === 'FON' || product.productType === 'SIC')) {
                            _this.selectedDocumentProductsMap[key].push(product);
                        }
                        var existingKidProduct = _this.selectedDocumentProductsKidMap[key].filter(function (selectedDocProduct) {
                            return selectedDocProduct.productKidLink === product.productKidLink;
                        });
                        if (!existingKidProduct.length && !!product.productKidLink) {
                            _this.selectedDocumentProductsKidMap[key].push(product);
                        }
                        if (!!product.productEsgLink && Object.keys(product.productEsgLink).length > 0) {
                            Object.keys(product.productEsgLink).forEach(function (esgStrategy) {
                                var suffix = esgStrategy !== 'N.A.' ? "_" + esgStrategy : '';
                                if (!_this.selectedDocumentESGProductsMap[key].find(function (esgLink) { return esgLink.title === "" + product.productName + suffix; })) {
                                    _this.selectedDocumentESGProductsMap[key].push({
                                        title: "" + product.productName + suffix,
                                        link: product.productEsgLink[esgStrategy]
                                    });
                                }
                            });
                        }
                    });
                }
            };
            this.getMaxSimulaButtonLabel = function (columnName, key, document) {
                document = !!document ? document : _this.filteredDocuments[key].documentsList[0];
                if (columnName === 'rejectProposal') {
                    return 'RIFIUTA';
                }
                else {
                    switch (document.attributes.documentState) {
                        case 'SIGNED':
                        case 'EXPIRED':
                        case 'INIT':
                            return _this.$filter('translate')('widget.sign_room.' + document.attributes.documentState);
                            break;
                        case 'SIGNING':
                            return _this.filteredDocuments[key].groupAdditionalInfo.currentSigner || _this.$filter('translate')('widget.sign_room.SIGNED');
                            break;
                        default:
                            return 'AVANTI';
                    }
                }
            };
            this.showAppropriateCheck = function (document) {
                return document.attributes.processMethodCode === 'ORD' && document.attributes.products.filter(function (product) { return product.notAppropriate; }).length > 0;
            };
            this.evalShowTab = function () {
                return !!_this.myDocumentsConfigService.myDocConfig && _this.myDocumentsConfigService.myDocConfig.showFilterBar && !_this.myDocumentsConfigService.myDocConfig.showFilters;
            };
            this.evalShowFilters = function () {
                return _this.myDocumentsConfigService.myDocConfig.showFilterBar && _this.myDocumentsConfigService.myDocConfig.showFilters;
            };
            this.evalOrderByLabel = function () {
                var orderByListIndex = _this.myDocumentsConfigService.myDocConfig.orderByListIndex;
                if (!_this.myDocumentsConfigService.myDocConfig.remotePagination) {
                    if (typeof (orderByListIndex) !== 'undefined') {
                        return (orderByListIndex[_this.indexOrderByFilter].title != undefined) ? 'widget.my_docs.order_by.' + orderByListIndex[_this.indexOrderByFilter].title : 'widget.my_docs.order_by.' + orderByListIndex[_this.indexOrderByFilter];
                    }
                }
                else {
                    return (_.isUndefined(_this.selectedOrdering)) ? '---' : 'widget.my_docs.order_by.' + _this.selectedOrdering.title;
                }
            };
            this.evalShowGroupByDropdown = function () {
                if (!!_this.myDocumentsConfigService.myDocConfig) {
                    var documentCategoryIndex = _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex;
                    var documentCategory = _this.myDocumentsConfigService.myDocConfig.documentCategories[documentCategoryIndex];
                }
                return documentCategory && documentCategory.listViewConf.groupSelectionEnabled && _this.myDocumentsConfigService.myDocConfig.viewType == 'list' && _this.documentFilterIndex !== MyDocumentsController.ADVISOR_PERSONAL_DOC_TAB;
            };
            this.evalGroupByLabel = function () {
                var myDocConfig = _this.myDocumentsConfigService.myDocConfig;
                return _this.groupByDocumentLabel == '' ? ('widget.my_docs.group_by.' + myDocConfig.documentCategories[myDocConfig.documentCategoryIndex].listViewConf.groupByList[_this.indexGroupByFilter]) : ('widget.my_docs.group_by.' + _this.groupByDocumentLabel);
            };
            this.evalDisplayedDocumentsInPage = function () {
                var documentCategoryIndex = !!_this.myDocumentsConfigService.myDocConfig ? _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex : undefined;
                if (!_.isUndefined(documentCategoryIndex) && _.isUndefined(_this.documentFilterIndex)) {
                    return (_this.myDocumentsConfigService.myDocConfig.viewType == 'grid') ? _this.paginationForGridView[documentCategoryIndex].itemsInPage : ((documentCategoryIndex == '' && _this.documentFilterIndex == '') ? _this.partialPageForTabView : _this.paginationForTabView[documentCategoryIndex].itemsInPage);
                }
                else {
                    return !!_this.filteredDocuments ? _this.filteredDocuments.length : 0;
                }
            };
            this.evalTotalDocumentsValue = function () {
                var myDocConfig = _this.myDocumentsConfigService.myDocConfig;
                return (!!myDocConfig && myDocConfig.viewType == 'grid') ? ((!!_this.badges[myDocConfig.categories.indexOf(myDocConfig.documentCategoryIndex)]) ? _this.evalTotalFilterDocument() : (!!_this.filteredDocuments ? _this.filteredDocuments.length : 0)) : ((!!myDocConfig && myDocConfig.documentCategoryIndex == '' && _this.documentFilterIndex == '') ? (_this.totalForTabView || '0') : (!!myDocConfig && !myDocConfig.showFilters || _this.documentFilterIndex == '') ? (!!_this.paginationForTabView[myDocConfig.documentCategoryIndex] ? _this.paginationForTabView[myDocConfig.documentCategoryIndex].size : '0') : (!!_this.badges[0] ? _this.badges[0].documentTypes[_this.documentFilterIndex] : '0'));
            };
            this.evalTotalFilterDocument = function () {
                if (!!_this.documentFilterIndex) {
                    return _this.badges[0].documentTypes[_this.documentFilterIndex];
                }
                else {
                    return _this.badges[_this.myDocumentsConfigService.myDocConfig.categories.indexOf(_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex)].totalDocuments;
                }
            };
            this.evalCurrencyValue = function (document, detailField) {
                return _this.myDocumentsConfigService.myDocConfig.documentCategories[document.topic].fieldsInAttributes.indexOf(detailField) == -1 ? (_this.$filter('number')(document[detailField], 2)) + ' ' + (_this.$filter('currencyToSym')(document[detailField + 'Iso'], 'utf8')) : (_this.$filter('number')(document.attributes[detailField], 2)) + ' ' + (_this.$filter('currencyToSym')(document.attributes[detailField + 'Iso'], 'utf8'));
            };
            this.evalFieldInAttributes = function (topic, field) {
                return _this.myDocumentsConfigService.myDocConfig.documentCategories[topic].fieldsInAttributes.indexOf(field) > -1;
            };
            this.evalNgClassForTable = function (index, sortColumnName, columnName, category) {
                var isOrdAsc = (_this.myDocumentsConfigService.myDocConfig.remotePagination) ? (typeof (_this.reverseOrderForBESorting[sortColumnName]) !== 'undefined' ? _this.reverseOrderForBESorting[sortColumnName].order == 'asc' : null) : (_this.indexOrderByFilter == index && typeof (_this.reverseOrder[category][columnName]) != 'undefined' && !_this.reverseOrder[category][columnName]);
                var isOrdDesc = (_this.myDocumentsConfigService.myDocConfig.remotePagination) ? (typeof (_this.reverseOrderForBESorting[sortColumnName]) !== 'undefined' ? _this.reverseOrderForBESorting[sortColumnName].order == 'desc' : null) : _this.indexOrderByFilter == index && typeof (_this.reverseOrder[category][columnName]) != 'undefined' && _this.reverseOrder[category][columnName];
                var FlexTableCellSortableValue = (_this.myDocumentsConfigService.myDocConfig.remotePagination && sortColumnName) || !_this.myDocumentsConfigService.myDocConfig.remotePagination;
                return {
                    'FlexTable__cell--large': index == 0,
                    'hide-for-small-only': index !== 0,
                    'is-ordAsc': isOrdAsc,
                    'is-ordDesc': isOrdDesc,
                    'FlexTable__cell--sortable': FlexTableCellSortableValue
                };
            };
            this.evalNgClassForGroupedTable = function (index) {
                return {
                    'FlexTable__cell--large': index == 0,
                    'is-ordAsc': _this.evalOrderTableClass('asc', index),
                    'is-ordDesc': _this.evalOrderTableClass('desc', index)
                };
            };
            this.evalOrderTableClass = function (order, index) {
                if (_this.myDocumentsConfigService.myDocConfig.remotePagination) {
                    var sortColumnName = (index == 0) ? 'KEY' : 'VALUE';
                    return typeof (_this.reverseOrderForBESorting[sortColumnName]) !== 'undefined' && _this.reverseOrderForBESorting[sortColumnName].order == order;
                }
                else {
                    var predicate = (order == 'asc') ? '!myDocumentsCtrl.reverseOrderForGroupedTable[' + index + ']' : 'myDocumentsCtrl.reverseOrderForGroupedTable[' + index + ']';
                    return _this.indexFieldForGroupedTable == index && _this.reverseOrderForGroupedTable[index] != undefined && _this.$scope.$eval(predicate);
                }
            };
            this.evalNgClassForGroupedTableIcon = function (index, isPdobTable) {
                index = isPdobTable ? "pdob-" + index : index;
                return {
                    'icon-plus-circle': !_this.expanded[index],
                    'icon-minus-circle': !!_this.expanded[index]
                };
            };
            this.getFieldValue = function (document, field) {
                var fieldValue = (field.attribute) ? document.attributes[field.fieldName] : document[field.fieldName];
                var companyName = (document && document.attributes) ? document.attributes['COMPANY'] : undefined;
                if (companyName === 'ALLFUNDSBANK' && field.fieldName === 'TYPE') {
                    fieldValue = undefined;
                }
                if (!!fieldValue) {
                    switch (field.fieldType) {
                        case 'currency':
                            var fieldIsoValue = (field.attribute) ? document.attributes[field.fieldName + 'Iso'] : document[field.fieldName + 'Iso'];
                            fieldValue = _this.$filter('number')(fieldValue, 2) + ' ' + _this.$filter('currencyToSym')(fieldIsoValue, 'utf8');
                            break;
                        case 'date':
                            fieldValue = _this.$filter('date')(fieldValue, 'shortDate');
                            break;
                    }
                }
                else {
                    fieldValue = '---';
                }
                return fieldValue;
            };
            this.isAFBDocument = function (document) {
                var field = { attribute: true, fieldName: 'COMPANY', fieldType: undefined };
                return _this.getFieldValue(document, field) === 'ALLFUNDSBANK';
            };
            this.evalSignRoomButton = function (document) {
                if (!_.isUndefined(document.attributes)) {
                    switch (document.attributes.documentState) {
                        case 'TO_SIGN':
                        case 'WAIT_MASTER_SIGNATURE':
                        case 'WAIT_MASTER_POSTSIGN_PROCESSING':
                        case 'SIGNING':
                            return (document.tags.indexOf('isCurrentSigner') > -1);
                            break;
                        default:
                            return true;
                    }
                }
            };
            this.isDocumentToSign = function (document) {
                return document.attributes.documentState === 'TO_SIGN';
            };
            this.getSignRoomButtonClass = function (document) {
                var buttonClass = ['button', 'small', 'no-margin'];
                if (document) {
                    switch (document.attributes.documentState) {
                        case 'SIGNED':
                        case 'INIT':
                        case 'EXPIRED':
                        case 'RETIRED':
                            return buttonClass;
                            break;
                        default:
                            return null;
                    }
                }
            };
            this.evalSignRoomButtonLabel = function (document) {
                if (document.tags.indexOf('isCurrentSigner') > -1) {
                    return _this.$filter('translate')('widget.sign_room.' + document.attributes.documentState);
                }
                else {
                    if (!_.isUndefined(document.attributes)) {
                        switch (document.attributes.documentState) {
                            case 'SIGNED':
                            case 'EXPIRED':
                            case 'INIT':
                                return _this.$filter('translate')('widget.sign_room.' + document.attributes.documentState);
                                break;
                            case 'SIGNING':
                                return document.attributes.currentSigner;
                                break;
                            default:
                                return document.attributes['currentSigner'];
                        }
                    }
                }
            };
            this.getColumnValueForGroupedTable = function (key, field) {
                return !!_this.getGroupedElements() && _this.getGroupedElements()[key].groupAdditionalInfo && _this.getGroupedElements()[key].groupAdditionalInfo[field] ? _this.getGroupedElements()[key].groupAdditionalInfo[field] : !!_this.getGroupedElements() ? _this.getGroupedElements()[key].documentsList.length : 0;
            };
            this.getColumnValueForGroupedPdobTable = function (key, field) {
                return !!_this.getGroupedPdobElements() && _this.getGroupedPdobElements()[key].groupAdditionalInfo && _this.getGroupedPdobElements()[key].groupAdditionalInfo[field] ? _this.getGroupedPdobElements()[key].groupAdditionalInfo[field] : !!_this.getGroupedPdobElements() ? _this.getGroupedPdobElements()[key].documentsList.length : 0;
            };
            this.getSignButtonStyle = function (documentState) {
                return (documentState == 'SIGNED') ? { 'cursor': 'default' } : {};
            };
            this.isAdvisorDocumentsTab = function () {
                return _this.documentFilterIndex === MyDocumentsController.ADVISOR_PERSONAL_DOC_TAB;
            };
            this.getDocumentCategoryIndex = function () {
                return _this.myDocumentsConfigService.getDocumentCategoryIndex();
            };
            this.isAzLifeLinkEnabled = function () {
                return MyDocumentsController.AZ_LIFE_LINK_ENABLED && _this.getDocumentCategoryIndex() === MyDocumentsController.AZ_LIFE_TAB;
            };
            this.goToAzLifeDocumentation = function () {
                _this.setWaitingState();
                _this.showAzLifeLinkError = false;
                _this.myDocumentsService.goToAzLifeDocumentation().then(function (response) {
                    window.open(response, '_blank');
                }, function () {
                    _this.showAzLifeLinkError = true;
                }).finally(function () { return _this.setWidgetReady(); });
            };
            this.isOwuiTab = function () {
                return !!_this.myDocumentsConfigService.myDocConfig && MyDocumentsController.CUSTOM_TAB_LIST.indexOf(_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex) > -1;
            };
            this.checkExistingDocumentForContractsTab = function () {
                return (_this.showPdobDocuments() && !!_this.pdbobDocuments && !_this.pdbobDocuments.length && !!_this.filteredDocuments && !_this.filteredDocuments.length) || (!_this.showPdobDocuments() && !!_this.filteredDocuments && _this.filteredDocuments.length == 0 && !_this.myDocumentsConfigService.myDocConfig.enablePolling);
            };
            this.getAcceptButtonClasses = function (document, key) {
                var classes = [document.attributes.documentState];
                if (!_this.isPracticeSignable(key, document)) {
                    classes.push('is-disabled');
                }
                return classes;
            };
            this.isAdvisorDocWidget = function () {
                return _this.myDocumentsService.widgetType === MyDocumentsController.ADVISOR_WIDGET_NAME;
            };
            this.isAdvisorCommunication = function () {
                return !!_this.myDocumentsConfigService && !!_this.myDocumentsConfigService.myDocConfig && _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex === MyDocumentsController.ADVISOR_COMMUNICATION;
            };
            this.isAdvisorReporting = function () {
                return !!_this.myDocumentsConfigService && !!_this.myDocumentsConfigService.myDocConfig && _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex === MyDocumentsController.ADVISOR_REPORTING;
            };
            this.showAdvisorFilter = function () {
                return _this.isAdvisorDocWidget() && !_this.isAdvisorCommunication() && !_this.isAdvisorReporting();
            };
            this.resetAdvisorFilter = function () {
                _this.advisorSearchCustomerCode = undefined;
                _this.advisorSearchNominative = undefined;
                _this.advisorSearchDocType = undefined;
                _this.advisorSearchStartDate = undefined;
                _this.advisorSearchEndDate = undefined;
                _this.isAdvisorSearch = false;
            };
            this.advisorFilter = function () {
                _this.searchAdvisorFilter(_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex);
            };
            this.widgetId = (typeof $scope['identifier'] !== 'undefined') ? $scope['identifier'] : (typeof $scope['widget'] != 'undefined') ? $scope['widget'].id : 'widgetMyDocuments';
            this.widgetConfig = initConfigService[this.widgetId];
            if (this.widgetConfig == undefined) {
                $scope['widget'] = $scope['widget'] || {};
                $scope['widget'].status = $scope['widget'].status || {};
                $scope['widget'].status.mode = $scope['widget'].status.mode || 'loading';
                this.widgetConfig = {};
                this.widgetConfig.status = $scope['widget'].status;
            }
            this.widgetProp = {
                'is-touch': this.isTouch(),
                'is-advisor-communication': false,
                'is-advisor-reporting': false,
            };
            if (!$scope['widget'].widgetInfos) {
                this.title = "myDocuments";
                var widgetType = this.myDocumentsService.widgetType;
                if (!!widgetType) {
                    this.myDocumentsService.getConfiguration.get({ widgetType: widgetType }, function (response) {
                        _this.initWidget(response.content.widgetInfos.CONFIGURATION.value);
                    }, function (error) {
                        _this.handlerWidgetError({ type: 'function', error: error });
                    });
                }
            }
            else {
                this.title = (this.widgetConfig.widgetInfos.TITLE) ? this.widgetConfig.widgetInfos.TITLE.value : "myDocuments";
                this.initWidget($scope['widget'].widgetInfos['CONFIGURATION'].value);
            }
            this.EventService.subscribe('openDocumentModal', function (document) {
                _this.showDetails(document);
            });
            this.owuiWidgetEventHandler = this.EventService.subscribe('owuiWidgetDirective:widgetLoaded', function (widgetName) {
                var widgetCommunicationTag = OwuiWidgetConstant.OwuiWidgetMap[0 /* WIDGET_COMMUNICATION */].widgetTag;
                if (widgetName === widgetCommunicationTag) {
                    console.log('owuiWidgetDirective:widgetLoaded', widgetName);
                    setTimeout(function () {
                        var widgetEl = document.getElementsByTagName(widgetCommunicationTag)[0];
                        if (!!widgetEl) {
                            var shadowRoot = widgetEl.shadowRoot;
                            var elements = shadowRoot.querySelectorAll(MyDocumentsController.WIDGET_COMMUNICATION_FAST_CLICK_ELEMENTS.toString());
                            elements.forEach(function (element) {
                                element.addEventListener('touchstart', function (e) {
                                    e.stopImmediatePropagation();
                                }, { passive: false });
                            });
                        }
                        _this.setWidgetReady();
                    }, 1000);
                    _this.downloadSuccessCommunicationEventHandler(widgetCommunicationTag);
                }
            });
            var handlerModalClosed = this.EventService.subscribe('modalClosed', function () {
                _this.$scope['detailsTabIndex'] = 0;
            });
            this.$scope.$on("$destroy", function () {
                _this.EventService.unsubscribe(handlerModalClosed);
                _this.EventService.unsubscribe(_this.owuiWidgetEventHandler);
            });
            $scope.$watchCollection(function () { return _this.filteredDocuments; }, function (newValue) {
                if (newValue != undefined) {
                    if (_this.myDocumentsConfigService.myDocConfig.documentCategories[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex] == undefined || (_this.myDocumentsConfigService.myDocConfig.documentCategories[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex] && !_this.myDocumentsConfigService.myDocConfig.remotePagination))
                        (_this.paginationForGridView[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex]) ? _this.paginationForGridView[_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex].updateItems(_this.filteredDocuments) : '';
                }
            });
        }
        MyDocumentsController.prototype.cleanDynamicWidgetConfiguration = function (documentCategoriesToShow) {
            this.myDocumentsConfigService.myDocConfig.categories = documentCategoriesToShow;
            for (var categoryName in this.myDocumentsConfigService.myDocConfig.documentCategories) {
                if (documentCategoriesToShow.indexOf(categoryName) == -1) {
                    delete this.myDocumentsConfigService.myDocConfig.documentCategories[categoryName];
                }
            }
        };
        MyDocumentsController.prototype.initWidgetAfterConfiguration = function () {
            if (!this.myDocumentsConfigService.myDocConfig.remotePagination) {
                this.initWidgetWithFrontPagination();
            }
            else {
                this.initWidgetWithRemotePagination();
            }
        };
        MyDocumentsController.prototype.initWidgetWithRemotePagination = function (landingCategory, view, setLandingPage) {
            var _this = this;
            this.genericDocumentsPaginatedServiceParams = new serviceData.GenericDocumentsPaginatedServiceParams();
            this.initWidgetSettings(landingCategory, view);
            this.setFilterInfoForSummary();
            this.docTagsForSummary = _.isUndefined(this.docTagsForSummary) ? this.myDocumentsConfigService.myDocConfig.summaryTagsList : this.docTagsForSummary;
            var communicationIndex = this.myDocumentsConfigService.myDocConfig.categories.indexOf(MyDocumentsController.TAB_COMMUNICATION_NAME);
            if (communicationIndex > -1)
                this.myDocumentsConfigService.myDocConfig.categories.splice(communicationIndex, 1);
            this.myDocumentsService.getDocumentSummary(this.myDocumentsConfigService.myDocConfig.categories, this.docTagsForSummary).then(function (documentSummary) {
                if (_this.myDocumentsService.widgetType !== MyDocumentsController.ADVISOR_SIGN_ROOM_WIDGET_NAME && _this.myDocumentsService.widgetType !== MyDocumentsController.ADVISOR_WIDGET_NAME && _this.myDocumentsConfigService.myDocConfig.categories.indexOf('signRoom') < 0 && _this.myDocumentsConfigService.myDocConfig.categories.indexOf('news') < 0) {
                    _this.myDocumentsService.getCommunicationDocumentUnread.get({}, function (communicationSummary) {
                        _this.myDocumentsService.getRendicontiDocumentUnread.get({}, function (rendicontiSummary) {
                            _this.handleDocumentSummary(setLandingPage, documentSummary, {
                                communication: communicationSummary.result,
                                rendiconti: rendicontiSummary.result,
                            });
                        });
                    });
                }
                else {
                    _this.handleDocumentSummary(setLandingPage, documentSummary);
                }
            }, function (error) {
                _this.handlerWidgetError(error);
            });
        };
        MyDocumentsController.prototype.handleDocumentSummary = function (setLandingPage, documentSummary, icaroSummary) {
            this.badges = documentSummary;
            if (!!icaroSummary) {
                this.badges.push({
                    documentCategory: this.getCommunicationTabName(),
                    documentTypes: { unread: icaroSummary.communication }
                });
                var summaryRendiconti = this.badges.find(function (badge) { return badge.documentCategory === 'summaryPdf'; });
                if (!!summaryRendiconti) {
                    summaryRendiconti.documentTypes = {
                        unread: (summaryRendiconti.documentTypes.unread || 0) + icaroSummary.rendiconti
                    };
                }
            }
            console.log('badge', this.badges);
            (!setLandingPage) ? this.setLandingPage() : null;
            this.setOrderByList();
            this.setSelectedOrdering();
            this.initRemotePaginations(documentSummary);
            this.handleGroupingView();
        };
        MyDocumentsController.prototype.getCommunicationBadge = function () {
            var _this = this;
            return !!this.badges && this.badges.find(function (badge) { return badge.documentCategory === _this.getCommunicationTabName(); });
        };
        MyDocumentsController.prototype.setFilterInfoForSummary = function () {
            var _this = this;
            angular.forEach(this.myDocumentsConfigService.myDocConfig.documentCategories, function (categoryConf, categoryName) {
                if (categoryConf.filtersList.length > 0) {
                    (_.isUndefined(_this.docTagsForSummary)) ? _this.docTagsForSummary = [] : null;
                    for (var i = 0; i < categoryConf.filtersList.length; i++) {
                        _this.docTagsForSummary.push(categoryConf.filtersList[i]);
                    }
                }
            });
        };
        MyDocumentsController.prototype.handleGroupingView = function () {
            var defaultGrouping = this.myDocumentsConfigService.myDocConfig.documentCategories[this.myDocumentsConfigService.myDocConfig.documentCategoryIndex].listViewConf.defaultGrouping;
            if (this.myDocumentsConfigService.myDocConfig.viewType == 'list' && defaultGrouping) {
                this.changeGroupWithRemotePagination(defaultGrouping);
            }
            else {
                this.setDefaultOrdering();
                if (!this.myDocumentsConfigService.myDocConfig.showFilters) {
                    this.getPaginatedDocuments(this.myDocumentsConfigService.myDocConfig.documentCategoryIndex, 1);
                }
                else {
                    this.applyFilter(this.documentFilterIndex);
                }
            }
        };
        MyDocumentsController.prototype.initWidgetWithFrontPagination = function (landingCategory, view) {
            var _this = this;
            this.initWidgetSettings(landingCategory, view);
            this.setOrderByList();
            this.initParameterForFrontPagination();
            this.initDefaultGrouping();
            this.changeGroup(this.groupByDocumentLabel, false).then(function () {
                if (_this.widgetConfig.status.mode !== 'locked') {
                    var tag;
                    if (_this.myDocumentsConfigService.myDocConfig.documentCategoryIndex == 'unread')
                        tag = ['unread'];
                    _this.genericDocumentsServiceParams = new serviceData.GenericDocumentsServiceParams();
                    _this.genericDocumentsServiceParams.setDocCategories(_this.myDocumentsConfigService.myDocConfig.categories).setDocTags(tag).setWidgetId(_this.widgetId).setAdvisorSearchUserCode(_this.advisorSearchCustomerCode).setAdvisorSearchNominative(_this.advisorSearchNominative).setAdvisorSearchStartDate(_this.$filter('date')(_this.advisorSearchStartDate, 'dd/MM/yyyy')).setAdvisorSearchEndDate(_this.$filter('date')(_this.advisorSearchEndDate, 'dd/MM/yyyy')).setAdvisorSearchDocType(_this.advisorSearchDocType);
                    _this.getDocuments(_this.genericDocumentsServiceParams, _this.getGenericDocumentsCallback);
                }
            });
        };
        MyDocumentsController.prototype.setDefaultOrdering = function () {
            var ordering = this.selectedOrdering || this.myDocumentsConfigService.myDocConfig.documentCategories[this.myDocumentsConfigService.myDocConfig.documentCategoryIndex].defaultOrdering;
            if (!!ordering) {
                var order = ordering.ascending ? 'asc' : 'desc';
                var title = ordering.title;
                this.genericDocumentsPaginatedServiceParams.setSort(this.createSortParameter(title, order));
            }
        };
        MyDocumentsController.prototype.resetSettingsOnChangeTab = function () {
            this.myDocumentsConfigService.myDocConfig.viewType = this.myDocumentsConfigService.myDocConfig.defaultView;
            this.groupByDocumentLabel = 'document';
            this.isGroupedBy = false;
            this.showProposal = {};
            this.acknowledgmentMap = {};
            this.kiidAcknowledgmentMap = {};
            this.selectedDocument = undefined;
            this.showAzLifeLinkError = false;
            this.resetPagination();
            this.setSelectedOrdering();
            if (this.myDocumentsConfigService.myDocConfig.viewType === 'grid') {
                this.setOrderByList();
            }
            this.resetNestedTable();
        };
        MyDocumentsController.prototype.getPdobDocumentNameCategory = function () {
            return MyDocumentsController.PDOB_DOCUMENTS_CATEGORY;
        };
        MyDocumentsController.prototype.showPdobDocuments = function () {
            return !!this.myDocumentsConfigService.myDocConfig && this.myDocumentsConfigService.myDocConfig.documentCategoryIndex === 'contract' && this.myDocumentsService.widgetType === MyDocumentsController.ADVISOR_WIDGET_NAME;
        };
        MyDocumentsController.prototype.getNestedTableKey = function (key) {
            return this.showPdobDocuments() ? "pdob-" + key : key;
        };
        MyDocumentsController.prototype.handleOrderGroupedTable = function (index, field, isPdobTable) {
            this.resetNestedTable(isPdobTable);
            (!this.myDocumentsConfigService.myDocConfig.remotePagination) ? this.changeOrderFilterForGroupedTable(index) : this.sortGroupedTable(index, field, isPdobTable);
        };
        MyDocumentsController.prototype.countAndInsertNumberOfUnreadDocumentsForGroup = function (groupedItem) {
            for (var documentsGroupKey in groupedItem) {
                var documentsGroup = groupedItem[documentsGroupKey];
                documentsGroup.groupAdditionalInfo.totalUnreadDouments = 0;
                for (var documentIndex in documentsGroup.documentsList) {
                    var document = documentsGroup.documentsList[documentIndex];
                    if (document.tags.indexOf('unread') > -1) {
                        documentsGroup.groupAdditionalInfo.totalUnreadDouments++;
                    }
                }
            }
        };
        MyDocumentsController.prototype.getGroupedElements = function () {
            var lastGroupedItem = this.groupedTableService.lastGroupedItems[this.myDocumentsConfigService.myDocConfig.documentCategoryIndex];
            if (typeof (lastGroupedItem) !== 'undefined') {
                var lastGroupedItemToReturn = lastGroupedItem[this.groupedTableService.selectedGroup];
                if (this.myDocumentsConfigService.myDocConfig.documentCategories[this.myDocumentsConfigService.myDocConfig.documentCategoryIndex].listViewConf.showUnreadBadgeForGroup == true) {
                    this.countAndInsertNumberOfUnreadDocumentsForGroup(lastGroupedItemToReturn);
                }
                return lastGroupedItemToReturn;
            }
        };
        MyDocumentsController.prototype.getGroupedPdobElements = function () {
            var lastGroupedItem = this.groupedTableService.lastGroupedItems[MyDocumentsController.PDOB_DOCUMENTS_CATEGORY];
            if (typeof (lastGroupedItem) !== 'undefined') {
                var lastGroupedItemToReturn = lastGroupedItem[this.groupedTableService.selectedGroup];
                return lastGroupedItemToReturn;
            }
        };
        MyDocumentsController.prototype.createPagination = function (key, numberItemsInPageGridView, numberItemsInPageListView, totalDocuments) {
            var totalDocuments = totalDocuments;
            this.paginationForGridView[key] = new this.remotePaginationService([], numberItemsInPageGridView, 1);
            this.paginationForGridView[key].updateItems(totalDocuments);
            this.paginationForTabView[key] = new this.remotePaginationService([], numberItemsInPageListView, 1);
            this.paginationForTabView[key].updateItems(totalDocuments);
        };
        MyDocumentsController.prototype.resetNestedTable = function (isPdobTable) {
            var _this = this;
            if (!isPdobTable) {
                this.expanded = {};
                this.groupedTableService.nestedTableDocuments = {};
            }
            if (isPdobTable) {
                Object.keys(this.expanded).forEach(function (key) {
                    if (key.indexOf('pdob') > -1) {
                        _this.expanded[key] = false;
                        _this.groupedTableService.nestedTableDocuments[key] = {};
                    }
                });
            }
        };
        MyDocumentsController.prototype.applyFilterOnValue = function (field, fieldValue, document) {
            if (this.getFieldType(field, 'currencyFields')) {
                fieldValue = this.$filter('number')(fieldValue, 2) + ' ' + this.$filter('currencyToSym')(document[field + 'Iso'], 'utf8');
            }
            else if (this.getFieldType(field, 'dateFields')) {
                fieldValue = this.$filter('date')(fieldValue, 'shortDate');
            }
            return fieldValue;
        };
        MyDocumentsController.prototype.getFieldType = function (field, type) {
            return this.myDocumentsConfigService.myDocConfig[type].indexOf(field) > -1;
        };
        MyDocumentsController.prototype.handlerOrderTable = function (index, key, columnField) {
            (!this.myDocumentsConfigService.myDocConfig.remotePagination) ? this.changeOrderByFilter(index, false, key, columnField.columnName) : (columnField.sortColumnName) ? this.sortColumn(columnField.sortColumnName) : null;
        };
        MyDocumentsController.prototype.handlerOrderGrid = function (index, filter) {
            (this.myDocumentsConfigService.myDocConfig.remotePagination) ? this.sortGrid(filter, index) : this.changeOrderByFilter(index, true, this.myDocumentsConfigService.myDocConfig.documentCategoryIndex);
        };
        MyDocumentsController.prototype.handlerChangeGroup = function (groupName) {
            this.resetMassiveDownloadParameters();
            this.groupedTableService.reverseOrderForNestedTable = [[]];
            this.resetNestedTable();
            (!this.myDocumentsConfigService.myDocConfig.remotePagination) ? this.changeGroup(groupName, true) : this.changeGroupWithRemotePagination(groupName);
        };
        MyDocumentsController.prototype.isDocCategoryDefined = function (document) {
            return !!this.myDocumentsConfigService.myDocConfig.documentCategories[document.topic];
        };
        MyDocumentsController.prototype.getGridHasDownloadLink = function (document) {
            if (this.isDocCategoryDefined(document)) {
                return this.myDocumentsConfigService.myDocConfig.documentCategories[document.topic].gridViewConf.hasDownloadLink;
            }
        };
        MyDocumentsController.prototype.getGridHasModalDetail = function (document) {
            if (this.isDocCategoryDefined(document)) {
                return this.myDocumentsConfigService.myDocConfig.documentCategories[document.topic].gridViewConf.hasModalDetails;
            }
        };
        MyDocumentsController.prototype.getGridFieldList = function (document) {
            if (this.isDocCategoryDefined(document)) {
                return this.myDocumentsConfigService.myDocConfig.documentCategories[document.topic].gridViewConf.fieldsList;
            }
        };
        MyDocumentsController.prototype.evalDocumentCategoryIndex = function (value) {
            return this.myDocumentsConfigService.myDocConfig.documentCategoryIndex == value;
        };
        MyDocumentsController.prototype.evalShowOrderByMenu = function () {
            var myDocConfig = this.myDocumentsConfigService.myDocConfig;
            var documentCategoryIndex = myDocConfig.documentCategoryIndex;
            var documentCategory = myDocConfig.documentCategories[documentCategoryIndex];
            return documentCategory && myDocConfig.viewType == 'grid' && documentCategory.gridViewConf.orderBySelectionEnabled;
        };
        MyDocumentsController.prototype.getGroupByList = function () {
            var documentCategory = !!this.myDocumentsConfigService.myDocConfig ? this.myDocumentsConfigService.myDocConfig.documentCategories[this.myDocumentsConfigService.myDocConfig.documentCategoryIndex] : undefined;
            if (!!documentCategory && !!documentCategory.listViewConf.groupByList) {
                return Object.keys(documentCategory.listViewConf.groupByList);
            }
        };
        MyDocumentsController.prototype.getFilteredDocumentsForView = function () {
            if (!this.myDocumentsConfigService.myDocConfig.remotePagination) {
                var startIndex = this.paginationForGridView[this.myDocumentsConfigService.myDocConfig.documentCategoryIndex].startIndex;
                var endIndex = this.paginationForGridView[this.myDocumentsConfigService.myDocConfig.documentCategoryIndex].endIndex;
                return this.$filter('slice')(this.filteredDocuments, startIndex, endIndex);
            }
            else {
                return this.filteredDocuments;
            }
        };
        MyDocumentsController.prototype.getFilteredTableDocumentsForView = function (key) {
            if (!this.myDocumentsConfigService.myDocConfig.remotePagination) {
                var startIndex = this.paginationForTabView[key].startIndex;
                var endIndex = this.paginationForTabView[key].endIndex;
                return this.$filter('slice')(this.filteredDocumentsForTableView[key], startIndex, endIndex);
            }
            else {
                return this.filteredDocuments;
            }
        };
        MyDocumentsController.prototype.getFilteredDocCategories = function () {
            return this.$filter('filter')(this.myDocumentsConfigService.myDocConfig.documentCategories, { key: this.myDocumentsConfigService.myDocConfig.documentCategoryIndex });
        };
        MyDocumentsController.prototype.showCommunicationTab = function () {
            return env.enableCommunication === 'true' && !this.isOnboardingProcess() && this.myDocumentsService.widgetType !== MyDocumentsController.ADVISOR_WIDGET_NAME && this.myDocumentsConfigService.myDocConfig.documentCategoryIndex !== 'news';
        };
        MyDocumentsController.prototype.getCommunicationTabName = function () {
            return MyDocumentsController.TAB_COMMUNICATION_NAME;
        };
        MyDocumentsController.prototype.getAdvisorCommunicationTabName = function () {
            return MyDocumentsController.ADVISOR_COMMUNICATION;
        };
        MyDocumentsController.prototype.getAdvisorReportingTabName = function () {
            return MyDocumentsController.ADVISOR_REPORTING;
        };
        MyDocumentsController.prototype.isOwuiWidgetReady = function () {
            return this.getManifestAssets() && !!this.widgetProp;
        };
        MyDocumentsController.prototype.getManifestAssets = function () {
            return this.configurationService.manifestJson;
        };
        MyDocumentsController.prototype.downloadSuccessCommunicationEventHandler = function (widgetCommunicationTag) {
            var _this = this;
            return document.getElementsByTagName(widgetCommunicationTag)[0].addEventListener(widgetCommunicationTag, function (event) {
                if (event.detail.type == 'cordova-download') {
                    var url = "/api/icaro/communication/download/" + event.detail.data.docId;
                    showFile(url, 'pdf', event.detail.data.fileNamePdf, event.target);
                }
                var communicationBadgeIndex = _this.badges.indexOf(_this.badges.find(function (badge) { return badge.documentCategory === _this.getCommunicationTabName(); }));
                var communicationBadge = _this.badges[communicationBadgeIndex];
                if (communicationBadge.documentTypes.unread) {
                    _this.badges[communicationBadgeIndex].documentTypes.unread = communicationBadge.documentTypes.unread - 1;
                    _this.$scope.$apply();
                }
            });
        };
        MyDocumentsController.prototype.isPgOnboarding = function () {
            return !!this.filteredDocuments && this.filteredDocuments.length > 0 && this.filteredDocuments[0].attributes.processTypeCode.indexOf('MYAZIMUT_PG') > -1;
        };
        MyDocumentsController.prototype.getDocumentsForOnboarding = function () {
            var res = this.filteredDocuments;
            if (this.isPgOnboarding()) {
                var master = this.filteredDocuments.find(function (doc) { return doc.attributes.processTypeCode === 'MYAZIMUT_PG_SUBSCRIPTION'; });
                var addendum = this.filteredDocuments.find(function (doc) { return doc.attributes.processTypeCode === 'MYAZIMUT_PG_ADDENDUM'; });
                this.addendumDocument = !!master ? addendum : null;
                res = [master || addendum];
            }
            return res;
        };
        MyDocumentsController.RESI_Z = 'RESI_Z';
        MyDocumentsController.ADVISOR_PERSONAL_DOC_TAB = 'advisorDocuments';
        MyDocumentsController.AZ_LIFE_TAB = 'hazlifePdf';
        MyDocumentsController.AZ_LIFE_LINK_ENABLED = true;
        MyDocumentsController.TAB_COMMUNICATION_NAME = 'zcommunication';
        MyDocumentsController.ADVISOR_WIDGET_NAME = 'MyDocumentsDynamicAdvisor';
        MyDocumentsController.ADVISOR_SIGN_ROOM_WIDGET_NAME = 'signRoomForPromoter';
        MyDocumentsController.PDOB_DOCUMENTS_CATEGORY = 'pdobDocuments';
        MyDocumentsController.ADVISOR_COMMUNICATION = 'advisorCommunication';
        MyDocumentsController.ADVISOR_REPORTING = 'advisorReporting';
        MyDocumentsController.CUSTOM_TAB_LIST = [
            MyDocumentsController.TAB_COMMUNICATION_NAME,
            MyDocumentsController.ADVISOR_COMMUNICATION,
            MyDocumentsController.ADVISOR_REPORTING
        ];
        MyDocumentsController.WIDGET_COMMUNICATION_FAST_CLICK_ELEMENTS = [
            '.Communication',
            'input',
            '.Input',
            '.InputWrapper',
            '.multiselect',
            '.multiselect__tags',
            '.multiselect__select',
            '.multiselect__element',
            '.Datepicker',
            '.Datepicker__icon',
            '.Datepicker__wrapper',
            '.flatpickr-calendar',
            '.flatpickr-months',
            '.flatpickr-innerContainer',
            '.Communication__checkboxWrapper',
            '.Checkbox',
            '.Checkbox__checkmarkContainer',
            '.Checkbox__checkmark',
            '.Checkbox__label',
            '.Communication__checkboxSection',
            '.Communication__selectAllWrapper',
            '.InputSwitch',
            '.Label__close',
            'button',
            '.Communication__buttonWrapper',
            '.ComponentTable__download'
        ];
        return MyDocumentsController;
    })();
    exports.MyDocumentsController = MyDocumentsController;
});

